import { AppTab } from '../types';

export const CHANGE_APP_TAB = 'CHANGE_APP_TAB';
export interface ChangeAppTab {
  type: typeof CHANGE_APP_TAB;
  appTab: AppTab;
}

export function changeAppTab(appTab: AppTab): ChangeAppTab {
  return {
    type: CHANGE_APP_TAB,
    appTab,
  };
}
