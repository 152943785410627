import { dateUtils as du } from 'ca-shared';
import { colors } from '../../shared/theme';
import { Goal } from '../../shared/types';
import gh from '../../shared/utils/goal-helper';

export function getExampleGoals(): Array<Goal> {
  const result: Array<Goal> = [];
  const rootGoal = gh.createGoal({
    name: 'Goals for ' + new Date().getFullYear(),
    date: du.getFormattedDate(du.getEndOfYear(new Date())),
    color: colors.goal.white,
  });
  result.push(rootGoal);

  const addGoal = (goal: Goal) => {
    result.push(goal);
    return goal.id;
  };

  addGoal(
    gh.createGoal({
      parentId: rootGoal.id,
      name: 'Try myGoalCraft',
      color: colors.goal.indigo,
      date: du.getFormattedDate(du.getCurrentDate()),
      complete: true,
    }),
  );

  const warAndPeaceId = addGoal(
    gh.createGoal({
      parentId: rootGoal.id,
      name: 'Read War and Peace',
      color: colors.goal.blue,
    }),
  );
  addGoal(
    gh.createGoal({
      parentId: addGoal(
        gh.createGoal({
          parentId: addGoal(
            gh.createGoal({
              parentId: addGoal(
                gh.createGoal({
                  parentId: warAndPeaceId,
                  name: 'War and Peace. Book IV',
                  color: colors.goal.blue,
                }),
              ),
              name: 'War and Peace. Book III',
              color: colors.goal.blue,
            }),
          ),
          name: 'War and Peace. Book II',
          color: colors.goal.blue,
        }),
      ),
      name: 'War and Peace. Book I',
      color: colors.goal.blue,
    }),
  );

  const earnMoneyId = addGoal(
    gh.createGoal({
      parentId: rootGoal.id,
      name: '$3000 of savings',
      color: colors.goal.green,
    }),
  );
  addGoal(
    gh.createGoal({
      parentId: addGoal(
        gh.createGoal({
          parentId: earnMoneyId,
          name: '$2000 of savings',
          color: colors.goal.green,
        }),
      ),
      name: '$1000 of savings',
      color: colors.goal.green,
    }),
  );
  addGoal(
    gh.createGoal({
      parentId: addGoal(
        gh.createGoal({
          parentId: earnMoneyId,
          name: 'Get promotion',
          color: colors.goal.teal,
        }),
      ),
      name: 'Pass assessment',
      color: colors.goal.teal,
    }),
  );

  const pushupsId = addGoal(
    gh.createGoal({
      parentId: rootGoal.id,
      name: '100 push-ups/day. 100 days in a row',
      color: colors.goal.amber,
    }),
  );

  addGoal(
    gh.createGoal({
      parentId: addGoal(
        gh.createGoal({
          parentId: addGoal(
            gh.createGoal({
              parentId: rootGoal.id,
              name: 'Lose 10 pounds',
              color: colors.goal.red,
            }),
          ),
          name: 'Lose 5 pounds',
          color: colors.goal.red,
        }),
      ),
      name: 'Lose 1 pound',
      color: colors.goal.red,
    }),
  );

  addGoal(
    gh.createGoal({
      parentId: addGoal(
        gh.createGoal({
          parentId: pushupsId,
          name: '100 push-ups/day. 50 days in a row',
          color: colors.goal.amber,
        }),
      ),
      name: '100 push-ups/day. 10 days in a row',
      color: colors.goal.amber,
    }),
  );

  return result;
}
