import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      root: {
        margin: '5px auto',
        maxWidth: '700px',
        [theme.breakpoints.down(700)]: {
          maxWidth: '100%',
        },
      },
      panelSummary: {
        borderBottom: '1px solid #0000000d',
      },
      roadMapPanel: {
        flexDirection: 'column',
        maxWidth: '',
      },
      roadMapItem: {
        padding: '10px',
        margin: '10px 0',
      },
      roadMapItemTitle: {
        display: 'flex',
        alignItems: 'center',
        padding: '5px 0',
        margin: '0 0 10px 0',
        borderBottom: '1px solid #00000021',
      },
      roadMapItemIcon: {
        marginRight: '10px',
      },
      backupsContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        flex: 1,
      },
      spinner: {
        alignSelf: 'center',
      },
      backup: {
        display: 'flex',
        flexDirection: 'column',
        margin: '10px 0',
        border: '1px solid #f4511e54',
        padding: '8px',
        borderRadius: '3px',
      },
      restoreButton: {
        alignSelf: 'flex-end',
      },
      contacts: {
        flex: 1,
      },
    };
  },
  { name: 'gm-more-page' },
);
