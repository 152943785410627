import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import { Provider } from 'react-redux';
import store from '../../core/redux-store';
import '../../utils/window.utils'; // to init events
import GmRoot from '../gm-root';

const GmApp: React.FC = () => {
  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <GmRoot />
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default GmApp;
