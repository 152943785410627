import uuidv4 from 'uuid/v4';
import { Goal, GoalTreeNode } from '../types';

const rootId = '<root-id>';

const rootGoal: Goal = {
  id: rootId,
  name: 'exist',
  parentId: 'root goal has no parent',
};

type PropsForDiff = Omit<Goal, 'id' | 'parentId' | 'complete'>;
const propsListForDiff: Required<PropsForDiff> = {
  name: '',
  description: '',
  color: '',
  date: '',
};
const propsForDiff = Object.keys(propsListForDiff) as Array<
  keyof PropsForDiff
>;

class GoalHelper {
  public readonly rootId = rootId;

  public readonly rootGoal = rootGoal;

  public buildGoalTree = buildGoalTree;

  public getSubgoals = getSubgoals;

  public getCurrentGoals = getCurrentGoals;

  public createGoal = (data?: Partial<Omit<Goal, 'id'>>): Goal => {
    return {
      name: 'New Goal',
      parentId: rootId,
      ...data,
      id: uuidv4(), // has to be at the end !
    };
  };

  public findGoal = (goals: Array<Goal>, goalId: Goal['id']) => {
    return goals.find((iter) => iter.id === goalId);
  };

  public getDiff: (
    goal: Goal,
    goal2: Goal,
  ) => Partial<PropsForDiff> | null = (goal, updatedGoal) => {
    let result: Partial<PropsForDiff> | null = null;
    for (const iter of propsForDiff) {
      // tslint:disable-next-line: no-any
      if (!Object.is(goal[iter], updatedGoal[iter])) {
        if (!result) {
          result = {};
        }
        result[iter] = updatedGoal[iter];
      }
    }
    return result;
  };

  public deleteGoal = (
    goals: Array<Goal>,
    goalId: Goal['id'],
  ): Array<Goal> => {
    if (goalId) {
      const goalToDelete = this.findGoal(goals, goalId);
      if (goalToDelete) {
        return goals.reduce((result: Array<Goal>, iter) => {
          // goal to delete -> skip
          if (iter.id === goalToDelete.id) {
            return result;
          }
          // child -> move 1 level up
          if (iter.parentId === goalToDelete.id) {
            result.push({
              ...iter,
              parentId: goalToDelete.parentId,
            });
            return result;
          }

          result.push(iter);
          return result;
        }, []);
      }
    }
    return goals;
  };

  public hasSubgoals = (goals: Array<Goal>, goalId: Goal['id']) => {
    return Boolean(goals.find((iter) => iter.parentId === goalId));
  };
}

function getSubgoals({
  goal,
  goals,
  deep,
}: {
  goal: Goal;
  goals: Array<Goal>;
  deep: boolean;
}): Array<Goal> {
  const result: Array<Goal> = [];
  goals.forEach((iter) => {
    if (iter.parentId === goal.id) {
      result.push(iter);
      if (deep) {
        result.push(
          ...getSubgoals({
            goal: iter,
            goals,
            deep,
          }),
        );
      }
    }
  });
  return result;
}

function buildGoalTree(goal: Goal, goals: Array<Goal>): GoalTreeNode {
  const result: GoalTreeNode = { goal, children: [] };
  goals.forEach((goalIter) => {
    if (goalIter.parentId === goal.id) {
      result.children.push(buildGoalTree(goalIter, goals));
    }
  });
  return result;
}

function getCurrentGoals(
  goalTreeNodes: Array<GoalTreeNode>,
): Array<Goal> {
  const currentGoals: Array<Goal> = goalTreeNodes.reduce(
    (result: Array<Goal>, goalNode) => {
      if (goalNode.children.length) {
        const children = getCurrentGoals(goalNode.children);
        if (children.length) {
          result.push(...children);
          return result;
        }
      }
      if (!goalNode.goal.complete) {
        result.push(goalNode.goal);
      }
      return result;
    },
    [],
  );
  return currentGoals;
}

const goalHelper = new GoalHelper();
export default goalHelper;
