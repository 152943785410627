import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const {
      custom: { appBar, breakpoints },
      palette,
    } = theme;
    return {
      root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: palette.background.default,
        minWidth: `100%`,
        minHeight: `calc(100% - ${appBar.mobileHeigh}px)`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          minHeight: `calc(100% - ${appBar.heigh}px)`,
        },
      },
      contentContainer: {
        maxWidth: '500px',
        width: '500px',
        minHeight: '500px',
        [theme.breakpoints.down(510)]: {
          maxWidth: '100%',
          alignSelf: 'stretch',
          borderRadius: 0,
          boxShadow: 'unset',
        },
      },
      content: {
        height: '320px',
      },
      stepContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px',
        [theme.breakpoints.up(510)]: {
          padding: '30px',
        },
      },
      stepTitleContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px',
      },
      stepTitleHintButton: {
        backgroundColor: 'transparent',
        marginLeft: '4px',
        color: palette.primary.main,
      },
      stepTitleHintButtonIcon: {
        fontSize: '24px !important',
      },
      input: {
        margin: '4px',
      },
      durationFirstRowMargin: {},
      durationControlRow: {
        display: 'flex',
      },
      durationControl: {
        height: '58px',
        margin: '4px',
        flex: 1,
      },
      stepperRoot: {
        padding: '10px 0 20px',
      },
      stepLabelIconContainer: {
        '& svg': {
          color: '#00000026',
        },
      },
      actionArea: {
        display: 'flex',
        justifyContent: 'center',
        margin: '20px',
      },
      exampleButton: {
        width: 'calc(100% - 10px)',
        margin: '0px 10px 6px 5px',
      },
    };
  },
  { name: 'gm-craft-wizard' },
);

export type Classes = ReturnType<typeof useStyles>;
