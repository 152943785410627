import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const {
      custom: { appTabs, breakpoints, appBar },
      zIndex,
    } = theme;
    return {
      root: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        left: 0,
        height: `${appTabs.heighMobile}px`,
        zIndex: zIndex.appBar,
        backgroundColor: '#fff',
        borderTop: '1px solid #00000017',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          top: `${appBar.heigh}px`,
          height: 'unset',
          right: 'unset',
          backgroundColor: theme.palette.background.default,
          boxShadow: theme.shadows[5],
          width: `${appTabs.widthDesktop}px`,
        },
      },
      tabsFlexContainer: {
        justifyContent: 'space-between',
      },
      tabsIndicator: {
        bottom: 'unset',
        top: 0,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          bottom: 'unset',
          top: 'unset',
          width: '4px',
        },
      },
      tabRoot: {
        padding: '3px 12px 0',
        minHeight: `${appTabs.heighMobile}px`,
        height: `${appTabs.heighMobile}px`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          minHeight: `${appTabs.heighDesktop}px`,
          height: `${appTabs.heighDesktop}px`,
        },
      },
      tabWrapper: {
        fontSize: '12px',
        lineHeight: 1.5,
      },
      icon: {
        margin: '0 !important',
      },
    };
  },
  { name: 'gm-app-tabs' },
);
