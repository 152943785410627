import { Goal } from '../shared/types';

export interface UserData {
  goals: Array<Goal>;
  lastModified?: number;
  newUser?: boolean;
}

export enum AppTab {
  all = 'all',
  inProgress = 'inProgress',
  craft = 'craft',
  more = 'more',
}

export enum AppPage {
  main = 'main',
  welcome = 'welcome',
}

export interface LocalSettings {
  appPage: AppPage;
}

export interface StoreState {
  userData: UserData;
  selectedAppTab: AppTab;
  localSettings: LocalSettings;
  // INSERT HERE
}
