import {
  ADD_GOAL,
  AddGoal,
  CHANGE_GOALS,
  ChangeGoals,
  DELETE_GOALS,
  DeleteGoal,
  RESTORE_BACKUP,
  RestoreBackup,
  ShouldSaveUserData,
  SYNC_USER_DATA_COMPLETE,
  SyncUserDataComplete,
} from '../redux-actions';
import { Goal } from '../shared/types';
import gh from '../shared/utils/goal-helper';
import { UserData } from '../types/store-state.types';

type Goals = UserData['goals'];

const initialState: UserData = {
  goals: [],
};

function deleteGoals(
  state: Goals,
  goalIds: Array<Goal['id']>,
): Goals {
  if (goalIds.length) {
    const goalsToDelete = goalIds
      .map((iter) => state.find((goalIter) => goalIter.id === iter))
      .filter((iter) => Boolean(iter)) as Array<Goal>;

    const newState = state.reduce((result: Array<Goal>, iter) => {
      // mark goal for as deleted for sync
      const goalToDelete = goalsToDelete.find(
        (delIter) => delIter.id === iter.id,
      );
      if (goalToDelete) {
        return result;
      }

      // move nested goal 1 level up
      const parentToDel = goalsToDelete.find(
        (delIter) => delIter.id === iter.parentId,
      );
      if (parentToDel) {
        result.push({
          ...iter,
          parentId: parentToDel.parentId,
        });
        return result;
      }

      result.push(iter);
      return result;
    }, []);
    return newState;
  }
  return state;
}

function addGoal(state: Goals, goal: Goal): Goals {
  if (
    goal.parentId !== gh.rootId &&
    !gh.findGoal(state, goal.parentId)
  ) {
    // tslint:disable-next-line: no-console
    console.error(`Cannot find parent goal`, goal);
    return state;
  }
  return [...state, goal];
}

function changeGoals(
  state: Goals,
  changes: ChangeGoals['changes'],
): Goals {
  return state.map((iter) => {
    const goalChanges = changes.find(
      (changesIter) => changesIter.id === iter.id,
    );
    if (goalChanges) {
      return {
        ...iter,
        ...goalChanges,
      };
    } else {
      return iter;
    }
  });
}

export default function userData(
  state: UserData = initialState,
  action:
    | DeleteGoal
    | AddGoal
    | ChangeGoals
    | SyncUserDataComplete
    | RestoreBackup,
): UserData {
  if ((action as ShouldSaveUserData).saveUserData) {
    state = {
      ...state,
      lastModified: Date.now(),
    };
  }

  switch (action.type) {
    case DELETE_GOALS:
      return {
        ...state,
        goals: deleteGoals(state.goals, action.goalIds),
      };

    case ADD_GOAL:
      return {
        ...state,
        goals: addGoal(state.goals, action.goal),
      };

    case CHANGE_GOALS:
      return {
        ...state,
        goals: changeGoals(state.goals, action.changes),
      };

    case RESTORE_BACKUP:
      return {
        ...state,
        goals: action.goals,
      };

    case SYNC_USER_DATA_COMPLETE:
      if (
        !state.lastModified ||
        (action.userData.lastModified &&
          action.userData.lastModified > state.lastModified)
      ) {
        return {
          ...state,
          ...action.userData,
        };
      }
      return state;

    default:
      return state;
  }
}
