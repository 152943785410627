import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const { goal, breakpoints } = theme.custom;
    return {
      root: {
        display: 'flex',
        position: 'relative',
        borderRadius: '2px',
        userSelect: 'none',
        minHeight: goal.mobileHeight + 'px',
        width: `${goal.mobileWidth}px`,
        margin: `${goal.marginVert}px 0`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          minHeight: goal.desktopHeight + 'px',
          width: `${goal.desktopWidth}px`,
        },
      },
      rootEditing: {
        zIndex: 10,
      },
      goalRoot: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        overflow: 'hidden',
      },
      goalRootComplete: {
        opacity: 0.35,
        boxShadow: 'unset',
      },
      goalName: {
        lineHeight: 1,
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '6px 5px',
        fontSize: '12px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          fontSize: '14px',
        },
      },
      goalDescDivider: {
        margin: '0px 10px',
      },
      goalDesc: {
        lineHeight: 1,
        fontSize: '12px',
        flex: 1,
        margin: '6px 5px',
        maxHeight: '200px',
        overflowX: 'hidden',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
          borderRadius: '6px',
          background: '#0000008a',
        },
      },
      bottomRow: {
        position: 'relative',
        backgroundColor: '#0000003d',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '14px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          height: '20px',
        },
      },
      goalDate: {
        lineHeight: 1,
        color: theme.palette.common.white,
        fontSize: '10px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          fontSize: '12px',
        },
      },
      descIcon: {
        position: 'absolute',
        color: '#fff',
        left: '1px',
        fontSize: '12px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          left: '3px',
          fontSize: '15px',
        },
      },
      menu: {
        backgroundColor: theme.palette.common.white,
        width: `${goal.menu.width}px`,
        height: `${goal.menu.height}px`,
        position: 'absolute',
        boxShadow: theme.shadows[3],
        zIndex: 10,
        display: 'flex',
        flexDirection: 'column',
        left: 'calc(100% + 5px)',
        top: 0,
      },
      menuBackground: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        backgroundColor: '#00000085',
        left: 0,
        top: 0,
      },
      input: {
        width: '100%',
        border: 'unset',
        textAlign: 'center',
        outline: 'unset',
        background: 'unset',
      },
      popupPaper: {
        display: 'flex',
        flexDirection: 'column',
        padding: '5px',
      },
      completeMark: {
        right: '2px',
        bottom: '1px',
        position: 'absolute',
        fontSize: '12px',
        lineHeight: '1',
        backgroundColor: '#ffffff',
        color: '#000000',
        width: '14px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '3px',
        height: '13px',
        border: '1px solid #0000005e',
      },
      scrollAnchor: {
        pointerEvents: 'none',
        position: 'absolute',
        left: '-5px',
      },
    };
  },
  {
    name: 'gm-goal',
  },
);

export type GmGoalClasses = ReturnType<typeof useStyles>;
