import { LocalSettings } from '../types';

export const CHANGE_LOCAL_SETTINGS = 'CHANGE_LOCAL_SETTINGS';
export interface ChangeLocalSettings {
  type: typeof CHANGE_LOCAL_SETTINGS;
  changes: Partial<LocalSettings>;
}

export function changeLocalSettings(
  changes: Partial<LocalSettings>,
): ChangeLocalSettings {
  return {
    type: CHANGE_LOCAL_SETTINGS,
    changes,
  };
}
