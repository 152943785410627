import useTheme from '@material-ui/core/styles/useTheme';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TreeIcon from '@material-ui/icons/AccountTreeOutlined';
import MoreIcon from '@material-ui/icons/MoreOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React from 'react';
import { HammerIcon } from '../../shared/components/gm-hammer.icon';
import { ExtTheme } from '../../shared/theme';
import { logRender } from '../../shared/utils/log.utils';
import { AppTab } from '../../types';
import { useGmDispatch, useGmSelector } from '../custom-hooks';
import { useStyles } from './gm-app-tabs.styles';

interface AppTabsProps {}

export const GmAppTabsView: React.FC<AppTabsProps> = () => {
  logRender(GmAppTabsView);

  const classes = useStyles();

  const [{ changeAppTab }, dispatch] = useGmDispatch();
  const appTab = useGmSelector((state) => state.selectedAppTab);

  const {
    breakpoints,
    custom: { breakpoints: gmBreakpoints },
  } = useTheme<ExtTheme>();
  const isMobile = useMediaQuery(
    breakpoints.down(gmBreakpoints.desktop),
  );

  return (
    <div className={classes.root}>
      <Tabs
        classes={{
          flexContainer: classes.tabsFlexContainer,
          indicator: classes.tabsIndicator,
        }}
        value={appTab}
        onChange={(event, value) => {
          dispatch(changeAppTab(value));
        }}
        indicatorColor="primary"
        textColor="primary"
        orientation={isMobile ? 'horizontal' : 'vertical'}>
        <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: classes.tabWrapper,
          }}
          value={AppTab.all}
          label="All"
          icon={<TreeIcon className={classes.icon} />}
        />
        <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: classes.tabWrapper,
          }}
          value={AppTab.inProgress}
          label="In Progress"
          icon={<TrendingUpIcon className={classes.icon} />}
        />
        <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: classes.tabWrapper,
          }}
          value={AppTab.craft}
          label="Craft"
          icon={<HammerIcon className={classes.icon} />}
        />
        <Tab
          classes={{
            root: classes.tabRoot,
            wrapper: classes.tabWrapper,
          }}
          value={AppTab.more}
          label="More"
          icon={<MoreIcon className={classes.icon} />}
        />
      </Tabs>
    </div>
  );
};
