import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const {
      custom: { breakpoints },
    } = theme;
    return {
      root: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: '45px',
      },
      bgImage: {
        backgroundColor: '#718fce',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: -1,
      },
      quote: {
        color: '#fff',
        marginTop: '10px',
        width: '310px',
        fontSize: '12px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          marginTop: '14px',
          width: '452px',
          fontSize: '18px',
        },
      },
      quoteAuthor: {
        color: '#fff',
        width: '310px',
        fontSize: '12px',
        paddingRight: '5px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          width: '452px',
          fontSize: '18px',
        },
      },
      logoRow: {
        backgroundColor: '#000000a8',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'stretch',
        padding: '30px 0 0',
        margin: '90px 0 0',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          margin: '120px 0 0',
        },
      },
      logo: {
        maxWidth: '300px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          maxWidth: '500px',
        },
      },
      logoDesc: {
        color: '#fff',
        backgroundColor: '#0000005e',
        marginTop: '25px',
        alignSelf: 'stretch',
        fontSize: '14px',
        padding: '10px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          fontSize: '20px',
          padding: '20px',
        },
      },
      startButton: {
        backgroundColor: '#bd44b8',
        color: '#fff',
        borderRadius: '30px',
        width: '150px',
        height: '50px',
        margin: 'auto',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          borderRadius: '35px',
          width: '170px',
          height: '60px',
        },
        '&:hover': {
          backgroundColor: '#00000080',
        },
      },
    };
  },
  { name: 'gm-welcome-page' },
);
