import IconButton from '@material-ui/core/IconButton';
import ExpandIcon from '@material-ui/icons/Add';
import CollapseIcon from '@material-ui/icons/Remove';
import { csn } from 'ca-shared';
import React, { useRef, useState } from 'react';
import { Goal, GoalTreeNode } from '../../../shared/types';
import gh from '../../../shared/utils/goal-helper';
import { logRender } from '../../../shared/utils/log.utils';
import GmGoal from '../../gm-goal';
import { useStyles } from './gm-goal-tree-node.styles';

interface Props {
  goal: Goal;
  isFirst?: boolean;
  isLast?: boolean;
  children: Array<GoalTreeNode>;
  isRoot?: boolean;
  onGoalClick: (goalId: Goal['id'], element: HTMLElement) => void;
}

const GmGoalTreeNodeView: React.FC<Props> = (props) => {
  logRender(GmGoalTreeNodeView, props.goal.name);
  const classes = useStyles();
  const rootRef = useRef<HTMLDivElement>(null);
  const [state, setState] = useState({ collapsed: false });

  const {
    goal,
    children,
    isFirst,
    isLast,
    isRoot,
    onGoalClick,
  } = props;

  return (
    <div key={goal.id} className={classes.root} ref={rootRef}>
      {Boolean(goal.parentId !== gh.rootId) && !isRoot && (
        <div className={classes.preGoalConnections}>
          <div
            className={csn(
              classes.preGoalConnectionTop,
              classes.preGoalConnectionHoriz,
              { [classes.preGoalConnectionVert]: !isFirst },
            )}
          />
          <div
            className={csn(classes.preGoalConnectionBottom, {
              [classes.preGoalConnectionVert]: !isLast,
            })}
          />
        </div>
      )}

      {!isRoot && <GmGoal goal={goal} onClick={onGoalClick} />}

      {Boolean(children && children.length) && (
        <>
          {!isRoot && (
            <div className={classes.postGoalConnections}>
              <div className={classes.postGoalConnectionHoriz} />
              <IconButton
                className={classes.postGoalCollapseButton}
                onClick={() =>
                  setState({ collapsed: !state.collapsed })
                }>
                {state.collapsed ? (
                  <ExpandIcon className={classes.collapseIcon} />
                ) : (
                  <CollapseIcon className={classes.collapseIcon} />
                )}
              </IconButton>
            </div>
          )}

          <div
            className={csn(classes.children, {
              [classes.childrenHidden]: state.collapsed,
            })}>
            {children.map((childNodeIter, index, array) => {
              return (
                <GmGoalTreeNodeView
                  key={childNodeIter.goal.id}
                  goal={childNodeIter.goal}
                  children={childNodeIter.children}
                  isFirst={index === 0}
                  isLast={index === array.length - 1}
                  onGoalClick={onGoalClick}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export const GmGoalTreeNodeMemo = React.memo(GmGoalTreeNodeView);
