import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

const padding = 4;

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const { breakpoints } = theme.custom;
    return {
      root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        padding: `${padding}px`,
      },
      content: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        maxWidth: breakpoints.desktop,
        paddingBottom: '50px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          minWidth: `575px`,
          margin: 'auto',
        },
      },
      goal: {
        flex: 1,
        margin: `${padding}px`,
        minWidth: 'calc(100% - 8px)',
        [theme.breakpoints.up(breakpoints.mobileBig)]: {
          minWidth: `calc(50% - ${padding * 2 + 2 /*border*/}px)`,
        },
      },
      goalBigHight: {
        minHeight: '85px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          minHeight: '130px',
        },
      },
      hintButton: {
        position: 'fixed',
        backgroundColor: '#fff',
        bottom: '60px',
        right: '15px',
        height: '40px',
        width: '40px',
        boxShadow: theme.shadows[15],
        [theme.breakpoints.up(breakpoints.desktop)]: {
          bottom: '30px',
          right: '40px',
        },
      },
      hintIcon: {
        height: '40px',
        width: '40px',
      },
    };
  },
  { name: 'gm-current-goals' },
);
