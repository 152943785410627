export const minDuration = 3;
export const maxDuration = 999;
export const steps = ['Goal', 'Deadline', 'Milestone', 'First step'];
export const examples = {
  goals: [
    {
      text: 'Read War and Peace',
      name: 'Read War and Peace',
      desc: '',
    },
    {
      text: 'Earn $5000',
      name: 'Earn $5000',
      desc: '',
    },
    {
      text: 'Do 100 push-ups a day during the next 30 days',
      name: '100 push-ups/day. 30 days',
      desc:
        'Perform 100 push-ups a day every single day during the next 30 days',
    },
  ],
  milestones: [
    {
      text: 'Read Chapter 1 of War and Peace',
      name: 'War and Peace ch.1',
      desc: 'Read Chapter 1 of War and Peace',
    },
    {
      text: 'Earn $1000',
      name: 'Earn $1000',
      desc: '',
    },
    {
      text: 'Do 100 push-ups a day during the next 7 days',
      name: '100 push-ups/day. 7 days',
      desc:
        'Perform 100 push-ups a day every single day during the next 7 days',
    },
  ],
  firstSteps: [
    {
      text: 'Read 20 pages of War and Peace',
      name: 'Read 20 pages of War and Peace',
      desc: '',
    },
    {
      text: 'Earn $50',
      name: 'Earn $50',
      desc: '',
    },
    {
      text: 'Do 100 push-ups till the end of the day',
      name: '100 push-ups/day. 1 day',
      desc: 'Perform 100 push-ups till the end of the day',
    },
  ],
};
