import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import DescriptionIcon from '@material-ui/icons/DescriptionOutlined';
import { csn } from 'ca-shared';
import React from 'react';
import { colors } from '../../shared/theme';
import { Goal } from '../../shared/types';
import { logRender } from '../../shared/utils/log.utils';
import { useStyles } from './gm-goal.styles';

interface Props {
  className?: string;
  goal: Goal;
  showDesc?: boolean;
  onClick: (goalId: Goal['id'], element: HTMLElement) => void;
}

const GmGoalView: React.FC<Props> = ({
  goal,
  showDesc,
  onClick,
  className,
}) => {
  logRender(GmGoalView, goal.name);
  const classes = useStyles();
  const color = goal.color || colors.goal.default;

  return (
    <div
      className={csn(classes.root, className)}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onClick(goal.id, event.currentTarget);
      }}>
      <Paper
        elevation={2}
        className={csn(classes.goalRoot, {
          [classes.goalRootComplete]: goal.complete,
        })}
        style={{
          backgroundColor: color,
        }}>
        <Typography className={classes.goalName} align="center">
          {goal.name}
        </Typography>

        {showDesc && goal.description && (
          <>
            <Divider className={classes.goalDescDivider} />
            <Typography className={classes.goalDesc} align="center">
              {goal.description}
            </Typography>
          </>
        )}

        {(goal.date || goal.description) && (
          <div className={classes.bottomRow}>
            {goal.date && (
              <Typography className={classes.goalDate} align="center">
                {goal.date}
              </Typography>
            )}
            {goal.description && (
              <>
                <Divider />
                <DescriptionIcon className={classes.descIcon} />
              </>
            )}
          </div>
        )}
      </Paper>

      {goal.complete && (
        <Typography className={classes.completeMark}>
          {'✓'}
        </Typography>
      )}
    </div>
  );
};

export const GmGoalViewMemo = React.memo(GmGoalView);
