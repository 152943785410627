import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { csn } from 'ca-shared';
import { CaInfoButton } from 'ca-shared';
import React from 'react';
import gh from '../../shared/utils/goal-helper';
import { logRender } from '../../shared/utils/log.utils';
import { useGmSelector } from '../custom-hooks';
import GmGoal from '../gm-goal';
import { useStyles } from './gm-current-goals.styles';

export const GmCurrentGoalsView: React.FC = () => {
  logRender(GmCurrentGoalsView);

  const classes = useStyles();
  const goals = useGmSelector((state) => state.userData.goals);

  const rootNode = gh.buildGoalTree(gh.rootGoal, goals);
  const currentGoals = gh.getCurrentGoals(rootNode.children);

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {currentGoals.map((iter) => {
          return (
            <GmGoal
              className={csn(classes.goal, [
                classes.goalBigHight,
                currentGoals.length < 5,
              ])}
              key={iter.id}
              goal={iter}
              showDesc={true}
              onClick={() => 1}
            />
          );
        })}
      </div>
      <CaInfoButton
        className={classes.hintButton}
        buttonContent={
          <InfoIcon color="primary" className={classes.hintIcon} />
        }
        popupContent={
          <Typography>
            This page contains the goals that are{' '}
            <b>currently in progress</b>. Meaning they don't have
            sub-goals and they are not completed.
            <br />
            <br />
            For instance.
            <br />
            If the goal "Read chapter 1" has uncompleted sub-goal
            "Read 20 pages of chapter 1", the latest will be displayed
            here.
            <br />
            <br />
            This page exists to help maintain focus on things that
            have to be done right now, without spreading attention on
            anything else.
          </Typography>
        }
      />
    </div>
  );
};
