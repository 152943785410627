"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var logRenderStyle = 'color: #050; background-color: #eee;padding: 2px 6px;border-radius: 3px'; // tslint:disable:class-name no-any

exports.logRender = function (inst, params) {
  // tslint:enable:class-name no-any
  if (process.env.NODE_ENV !== 'production') {
    var name_1;

    switch (typeof inst) {
      case 'function':
        name_1 = inst.name;
        break;

      case 'object':
        name_1 = inst.constructor.name;
        break;

      default:
        name_1 = inst;
        break;
    } // tslint:disable:no-console


    if (params) {
      console.debug('%c<' + name_1 + ' />', logRenderStyle, params);
    } else {
      console.debug('%c<' + name_1 + ' />', logRenderStyle);
    } // tslint:enable:no-console

  }
}; // tslint:disable-next-line: no-any


exports.logError = function () {
  var args = [];

  for (var _i = 0; _i < arguments.length; _i++) {
    args[_i] = arguments[_i];
  }

  if (process.env.NODE_ENV !== 'production') {
    // tslint:disable-next-line:no-console
    console.error.apply(console, args);
  }
};