import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    return {
      button: {},
      popoverPaper: {
        maxWidth: '300px',
      },
      colorsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      color: {
        border: '1px solid #00000021',
        width: '142px',
        height: '95px',
        margin: '4px',
      },
    };
  },
  { name: 'gm-color-selection' },
);
