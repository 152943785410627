import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import { DatePicker } from '@material-ui/pickers';
import useTheme from '@material-ui/styles/useTheme';
import { CaInfoButton, csn, dateUtils as du } from 'ca-shared';
import React, { useCallback, useRef, useState } from 'react';
import { colors, ExtTheme } from '../../shared/theme';
import { Goal } from '../../shared/types';
import gh from '../../shared/utils/goal-helper';
import { logRender } from '../../shared/utils/log.utils';
import { useGmDispatch, useGmSelector } from '../custom-hooks';
import { GmConfirmationDialog } from '../gm-confirmation-dialog';
import { GmColorSelection } from './gm-color-selection';
import { getActions } from './gm-edit-goal.actions';
import { Classes, useStyles } from './gm-edit-goal.styles';

interface GmEditGoalProps {
  anchor: HTMLElement | null;
  goalId: Goal['id'] | null;
  onClose: () => void;
  onMoveToClick: (goal: Goal) => void;
}

const delSubgoalOptions = {
  delete: 'delete',
  moveOneLevelUp: 'moveOneLevelUp',
};

export const GmEditGoalView: React.FC<GmEditGoalProps> = ({
  anchor,
  goalId,
  onClose,
  onMoveToClick,
}) => {
  logRender(GmEditGoalView);

  const goals = useGmSelector((state) => state.userData.goals);
  const [
    { addGoal, changeGoals, deleteGoals },
    dispatch,
  ] = useGmDispatch();

  const goal = goalId && gh.findGoal(goals, goalId);
  const [editingGoal, setEditingGoal] = useState({ ...goal } as Goal);
  const [deleteDial, setDeleteDial] = useState({
    open: false,
    hasSubgoals: false,
    subgoalOption: delSubgoalOptions.moveOneLevelUp,
  });
  const [applyToSub, setApplyToSub] = useState(false);

  // mutable values
  const { current: refs } = useRef({
    prevOpen: false,
    prevGoal: null as typeof goal,
    // to avoid switching text before popup is closed
    completed: false as boolean | undefined,
  });

  const classes = useStyles();

  const {
    breakpoints,
    custom: { breakpoints: gmBreakpoints },
  } = useTheme<ExtTheme>();
  const isMinWidth = useMediaQuery(
    breakpoints.down(gmBreakpoints.min),
  );
  const isMobile = useMediaQuery(
    breakpoints.down(gmBreakpoints.desktop),
  );

  const handleClose = useCallback(() => {
    onClose();
    refs.prevOpen = false;
  }, [refs, onClose]);

  const handleDeleteDialogClose = useCallback(
    (confirm: boolean) => {
      if (!goal) {
        return;
      }
      if (confirm) {
        const goalsToDelete = [goal.id];
        const { subgoalOption, hasSubgoals } = deleteDial;
        if (hasSubgoals) {
          if (subgoalOption === delSubgoalOptions.delete) {
            const subgoals = gh.getSubgoals({
              goal,
              goals,
              deep: true,
            });
            goalsToDelete.push(...subgoals.map((iter) => iter.id));
          }
          // delSubgoalOptions.moveOneLevelUp is the default behavior of reducer
        }
        dispatch(deleteGoals(goalsToDelete));
        handleClose();
      }
      setDeleteDial({
        ...deleteDial,
        open: false,
      });
    },
    [goal, deleteDial, dispatch, deleteGoals, handleClose, goals],
  );

  if (!goal) {
    return null;
  }

  const justOpened = !refs.prevOpen && anchor;

  if (justOpened) {
    setApplyToSub(false);
    refs.completed = goal.complete;
    refs.prevOpen = true;
  }
  if (justOpened || refs.prevGoal !== goal) {
    setEditingGoal({ ...goal });
    refs.prevGoal = goal;
  }

  const handleDeleteClick = () => {
    setDeleteDial({
      ...deleteDial,
      open: true,
      subgoalOption: delSubgoalOptions.moveOneLevelUp,
      hasSubgoals: gh.hasSubgoals(goals, goal.id),
    });
  };

  const handleSave = () => {
    const diff = gh.getDiff(goal, editingGoal);
    const changesToSave: Parameters<typeof changeGoals>[0] = [
      {
        ...diff,
        id: editingGoal.id,
      },
    ];
    if (applyToSub && goal) {
      const subGoals = gh.getSubgoals({
        goal,
        goals,
        deep: true,
      });
      subGoals.forEach((iter) =>
        changesToSave.push({
          ...diff,
          id: iter.id,
        }),
      );
    }
    dispatch(changeGoals(changesToSave));
    handleClose();
  };

  let deleteGoalOptions: JSX.Element | null = null;
  if (deleteDial.hasSubgoals) {
    deleteGoalOptions = (
      <RadioGroup
        value={deleteDial.subgoalOption}
        onChange={(event, value) => {
          setDeleteDial({
            ...deleteDial,
            subgoalOption: value,
          });
        }}>
        <FormControlLabel
          value={delSubgoalOptions.moveOneLevelUp}
          control={<Radio color="primary" />}
          label="Move subgoals one level up"
        />
        <FormControlLabel
          value={delSubgoalOptions.delete}
          control={<Radio color="primary" />}
          label="Delete subgoals"
        />
      </RadioGroup>
    );
  }

  return (
    <Popover
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
      marginThreshold={isMinWidth ? 0 : 10}
      open={Boolean(anchor)}
      anchorEl={anchor}
      onClose={handleClose}
      onClick={(event) => event.stopPropagation()}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}>
      <div className={classes.content}>
        {formControlWithHint({
          control: (
            <TextField
              className={csn(classes.margin, classes.fullWidth)}
              label="Name"
              margin={isMobile ? 'dense' : 'none'}
              variant="outlined"
              value={editingGoal.name}
              onFocus={(event) => {
                event.currentTarget.select();
              }}
              onChange={(event) => {
                setEditingGoal({
                  ...editingGoal,
                  name: event.currentTarget.value,
                });
              }}
            />
          ),
          oldValue: goal.name,
          newValue: editingGoal.name,
          classes,
        })}

        {formControlWithHint({
          control: (
            <TextField
              className={csn(classes.margin, classes.fullWidth)}
              multiline={true}
              label="Description"
              margin={isMobile ? 'dense' : 'none'}
              variant="outlined"
              value={editingGoal.description}
              rowsMax={8}
              inputProps={{
                className: classes.descriptionInput,
              }}
              onChange={(event) => {
                setEditingGoal({
                  ...editingGoal,
                  description: event.currentTarget.value,
                });
              }}
            />
          ),
          oldValue: goal.description || '',
          newValue: editingGoal.description || '',
          classes,
        })}

        {formControlWithHint({
          control: (
            <DatePicker
              className={csn(classes.margin, classes.fullWidth)}
              label="Deadline"
              margin={isMobile ? 'dense' : 'none'}
              inputVariant="outlined"
              value={editingGoal.date || undefined}
              clearable={true}
              onChange={(date) => {
                setEditingGoal({
                  ...editingGoal,
                  date: date ? du.getFormattedDate(date) : undefined,
                });
              }}
              labelFunc={editingGoal.date ? undefined : () => ''}
            />
          ),
          oldValue: goal.date || 'none',
          newValue: editingGoal.date || 'none',
          classes,
        })}

        {formControlWithHint({
          control: (
            <GmColorSelection
              className={csn(classes.button, classes.fullWidth)}
              variant="contained"
              text="Color"
              selectedColor={editingGoal.color || colors.goal.default}
              onSelectColor={(color) => {
                setEditingGoal({
                  ...editingGoal,
                  color,
                });
              }}
            />
          ),
          oldValue: goal.color || 'Default',
          newValue: editingGoal.color || 'Default',
          classes,
        })}

        <Divider className={classes.margin} />

        {getActions({
          addGoal,
          changeGoals,
          classes,
          completed: Boolean(refs.completed),
          goal,
          dispatch,
          handleClose,
          handleDeleteClick,
          onMoveToClick,
        })}

        <Divider className={classes.margin} />

        <FormControlLabel
          className={classes.applyToSubCheckbox}
          control={
            <Checkbox
              checked={applyToSub}
              onChange={(event) =>
                setApplyToSub(event.currentTarget.checked)
              }
              color="primary"
            />
          }
          label="Apply to subgoals"
        />
      </div>

      <div className={classes.submissionArea}>
        <Button color="primary" onClick={handleClose}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>
      </div>

      <GmConfirmationDialog
        title={`Delete "${goal.name}" ?`}
        text={deleteGoalOptions ? 'Goal has subgoals' : ''}
        content={deleteGoalOptions}
        confirmButtonText="Delete"
        open={deleteDial.open}
        onClose={handleDeleteDialogClose}
      />
    </Popover>
  );
};

function formControlWithHint({
  control,
  classes,
  oldValue,
  newValue,
}: {
  control: JSX.Element;
  classes: Classes;
  oldValue: string;
  newValue: string;
}) {
  return (
    <div className={classes.hintContainer}>
      {control}
      {!(oldValue === newValue) && (
        <CaInfoButton
          className={classes.hintButton}
          buttonContent={<EditIcon />}
          popupContent={'The value was modified'}
        />
      )}
    </div>
  );
}
