import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { GmEpicMiddleWare } from '../types';
import { userDataChangeTriggerEpic } from './synchronizer.middleware';
import { syncUserDataEpic } from './user-data.middleware';

export const epicMiddleware: GmEpicMiddleWare = createEpicMiddleware();

export const rootEpic = combineEpics(
  syncUserDataEpic,
  userDataChangeTriggerEpic,
);
