import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const { breakpoints } = theme.custom;
    return {
      root: {
        position: 'sticky',
        left: 0,
        right: 0,
        top: 0,
        background: theme.palette.primary.main,
        zIndex: theme.zIndex.appBar,
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px',
        transition: theme.transitions.create('transform'),
        height: theme.custom.appBar.heigh + 'px',
        [theme.breakpoints.down(breakpoints.desktop)]: {
          height: theme.custom.appBar.mobileHeigh + 'px',
        },
      },
      rootUat: {
        background: amber[600],
      },
      rootDev: {
        background: green[600],
      },
      rootScrolled: {
        // we don't want to scroll for desktop layout
        // because then we will need to do something with the tabs
        [theme.breakpoints.down(breakpoints.desktop)]: {
          transform: 'translateY(-100%)',
        },
      },
      logoContainer: {
        position: 'relative',
        height: '40px',
        marginTop: '10px',
        [theme.breakpoints.down(breakpoints.desktop)]: {
          height: '32px',
          marginTop: '5px',
        },
      },
      logo: {
        height: '100%',
      },
      icon: {
        height: '31px',
        margin: '0 10px 5px 0',
        [theme.breakpoints.down(breakpoints.desktop)]: {
          height: '25px',
          margin: '0 10px 7px 0',
        },
      },
      alphaSign: {
        position: 'absolute',
        bottom: '-5px',
        left: '120px',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          left: '157px',
        },
      },
      actionsArea: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '0 8px',
      },
      homeButton: {
        color: '#fff',
        [theme.breakpoints.up(breakpoints.desktop)]: {
          margin: '0 5px',
        },
      },
      loginArea: {
        display: 'flex',
        alignItems: 'center',
        color: theme.palette.common.white,
      },
    };
  },
  {
    name: 'gm-app-bar',
  },
);

export type Classes = ReturnType<typeof useStyles>;
