import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

const margin = 6;

export const useStyles = makeStyles(
  ({
    breakpoints: appBreakpoints,
    custom: { breakpoints },
  }: ExtTheme) => {
    return {
      root: {},
      paper: {
        minWidth: '320px',
        display: 'flex',
        flexDirection: 'column',
        [appBreakpoints.down(breakpoints.min)]: {
          maxWidth: 'unset',
          maxHeight: '100%',
          borderRadius: 0,
          left: '0 !important',
          right: '0 !important',
          top: '0 !important',
          bottom: '0 !important',
        },
      },
      content: {
        flex: 1,
        overflow: 'auto',
        marginBottom: '80px',
        display: 'flex',
        flexDirection: 'column',
        minWidth: '290px',
        width: '290px',
        padding: '15px',
        [appBreakpoints.up(breakpoints.desktop)]: {
          minWidth: '390px',
        },
      },
      descriptionInput: {
        lineHeight: '1.3',
        minHeight: '19px',
      },
      margin: {
        margin: `${margin}px ${margin}px`,
      },
      fullWidth: {
        flex: 1,
      },
      hintContainer: {
        position: 'relative',
        display: 'flex',
      },
      hintButton: {
        position: 'absolute',
        boxShadow: '0 0 3px #5f5f5f',
        right: '-6px',
        top: 'calc(50% - 13px)',
      },
      button: {
        fontSize: '12px',
        margin: `${margin}px`,
        height: '56px',
      },
      actionsArea: {
        display: 'flex',
        flexDirection: 'column',
      },
      actionButtonRow: {
        display: 'flex',
      },
      actionButton: {
        flex: 1,
        padding: '6px',
      },
      actionButtonLabel: {
        display: 'flex',
        flexDirection: 'column',
      },
      actionButtonText: {
        fontSize: '14px',
      },
      submissionArea: {
        display: 'flex',
        justifyContent: 'flex-end',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: '8px',
      },
      applyToSubCheckbox: {
        margin: '-7px -7px 0 0',
      },
    };
  },
  { name: 'gm-edit-goal' },
);

export type Classes = ReturnType<typeof useStyles>;
