import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { useRef } from 'react';
import { logRender } from '../../shared/utils/log.utils';
// import { useStyles } from './gm-confirmation-dialog.styles';

interface Props {
  open: boolean;
  title: string;
  text?: string;
  content?: JSX.Element | null;
  confirmButtonText?: string;
  cancelButtonText?: string;
  hideConfirm?: boolean;
  onClose: (confirm: boolean) => void;
}

const GmConfirmationDialogView: React.FC<Props> = ({
  open,
  confirmButtonText,
  cancelButtonText,
  title: titleProp,
  text: textProp,
  hideConfirm,
  content,
  onClose,
}) => {
  logRender(GmConfirmationDialogView);

  // const classes = useStyles();

  const cachedText = useRef({
    text: textProp,
    title: titleProp,
  });

  cachedText.current.text = textProp || cachedText.current.text;
  cachedText.current.title = titleProp || cachedText.current.title;
  const { text, title } = cachedText.current;

  const handleClose = () => onClose(false);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {Boolean(text) && (
          <DialogContentText>{text}</DialogContentText>
        )}
        {Boolean(content) && content}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {cancelButtonText || 'Cancel'}
        </Button>
        {!hideConfirm && (
          <Button
            onClick={() => onClose(true)}
            color="primary"
            autoFocus={true}>
            {confirmButtonText || 'Proceed'}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export const GmConfirmationDialogMemo = React.memo(
  GmConfirmationDialogView,
);
