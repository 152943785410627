import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import { CaLogin, csn } from 'ca-shared';
import React, { useCallback, useEffect, useState } from 'react';
import { Subject } from 'rxjs';
import { auditTime, pairwise } from 'rxjs/operators';
import { logo } from '../../assets';
import auth from '../../core/auth';
import { GmText } from '../../shared/components';
import { appBar } from '../../shared/theme';
import { logRender } from '../../shared/utils/log.utils';
import { AppPage, AppTab } from '../../types';
import { useGmDispatch } from '../custom-hooks';
import { useStyles } from './gm-app-bar.styles';

interface Props {
  rootScroll$: Subject<number>;
  appTab: AppTab;
}

const stage = process.env.REACT_APP_STAGE;

if (stage !== 'prod') {
  console.log(stage); // tslint:disable-line:no-console
}

function useScrollMonitor(
  rootScroll$: Subject<number>,
  scrolled: boolean,
  setScrolled: (scrolled: boolean) => void,
) {
  useEffect(() => {
    const subscription = rootScroll$
      .pipe(pairwise())
      .pipe(auditTime(100))
      .subscribe((value) => {
        if (value[0] > value[1]) {
          if (scrolled) {
            setScrolled(false);
          }
        } else if (appBar.heigh < value[1]) {
          if (!scrolled) {
            setScrolled(true);
          }
        }
      });
    return () => subscription.unsubscribe();
  });
}

export const GmAppBarView: React.FC<Props> = ({
  rootScroll$,
  appTab,
}) => {
  logRender(GmAppBarView);

  const classes = useStyles();
  const [scrolled, setScrolled] = useState(false);
  useScrollMonitor(rootScroll$, scrolled, setScrolled);
  const handleLogin = useCallback(() => auth.login(), []);
  const handleLogout = useCallback(() => auth.logout(), []);
  useEffect(() => setScrolled(false), [appTab]);
  const [{ changeLocalSettings }, dispatch] = useGmDispatch();

  return (
    <div
      className={csn(
        classes.root,
        [classes.rootScrolled, scrolled],
        [classes.rootUat, stage === 'uat'],
        [classes.rootDev, stage === 'dev'],
      )}>
      <div className={classes.logoContainer}>
        <img src={logo} alt="GoalCraft" className={classes.logo} />
        <GmText
          className={classes.alphaSign}
          custom={{
            color: 'white',
            size: 'xsmall',
          }}>
          &alpha;lpha
        </GmText>
      </div>

      <div className={classes.actionsArea}>
        <IconButton
          className={classes.homeButton}
          onClick={() => {
            dispatch(
              changeLocalSettings({ appPage: AppPage.welcome }),
            );
          }}>
          <HomeIcon color="inherit" />
        </IconButton>

        <CaLogin
          className={classes.loginArea}
          userEmail={auth.authorized ? auth.email : undefined}
          userName={auth.name}
          userPicture={auth.picture}
          onLogin={handleLogin}
          onLogout={handleLogout}
        />
      </div>
    </div>
  );
};
