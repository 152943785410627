"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var styles_1 = require("@material-ui/styles");

exports.useStyles = styles_1.makeStyles(function (theme) {
  return {
    root: {
      backgroundColor: '#00000038'
    },
    button: {
      backgroundColor: '#bdbbbb',
      width: '26px',
      height: '26px',
      padding: '0px',
      '&:hover': {
        backgroundColor: '#ececec'
      },
      '& svg': {
        fontSize: '16px'
      }
    },
    content: {
      margin: '16px',
      minHeight: '50px',
      minWidth: '150px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    actionArea: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '10px'
    },
    closeButton: {}
  };
}, {
  name: 'ca-info-button'
});