"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var styles_1 = require("@material-ui/styles");

exports.useStyles = styles_1.makeStyles(function (theme) {
  return {
    root: {
      height: '45px',
      width: '45px'
    }
  };
}, {
  name: 'ca-avatar'
});