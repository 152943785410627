import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const { goal, breakpoints } = theme.custom;
    return {
      root: {
        display: 'flex',
        alignItems: 'flex-start',
      },
      children: {
        display: 'flex',
        flexDirection: 'column',
      },
      childrenHidden: {
        display: 'none !important',
      },
      goalContainer: {
        border: goal.connectionLine,
      },
      preGoalConnections: {
        display: 'flex',
        flexDirection: 'column',
        width: goal.connectionWidth + 'px',
        alignSelf: 'stretch',
      },
      preGoalConnectionTop: {
        height: `${goal.halfOfMobileHeight + goal.marginVert}px`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          height: `${goal.halfOfDeskTopHeight + goal.marginVert}px`,
        },
      },
      preGoalConnectionBottom: {
        flex: 1,
      },
      preGoalConnectionHoriz: {
        borderBottom: goal.connectionLine,
      },
      preGoalConnectionVert: {
        borderLeft: goal.connectionLine,
      },
      postGoalConnections: {
        display: 'flex',
        flexDirection: 'column',
        width: goal.connectionWidth + 'px',
        position: 'relative',
      },
      postGoalConnectionHoriz: {
        borderBottom: goal.connectionLine,
        height: `${goal.halfOfMobileHeight + goal.marginVert}px`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          height: `${goal.halfOfDeskTopHeight + goal.marginVert}px`,
        },
      },
      postGoalConnectionTop: {
        height: `${goal.halfOfMobileHeight + goal.marginVert}px`,
      },
      postGoalConnectionBottom: {
        flex: 1,
      },
      postGoalCollapseButton: {
        backgroundColor: '#fff',
        position: 'absolute',
        left: goal.connectionWidth / 2 + 'px',
        top: `${goal.halfOfMobileHeight +
          goal.marginVert -
          goal.halfOfConnectionWidth}px`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          top: `${goal.halfOfDeskTopHeight +
            goal.marginVert -
            goal.halfOfConnectionWidth}px`,
        },
        width: goal.connectionWidth + 'px',
        height: goal.connectionWidth + 'px',
        border: goal.connectionLine,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 0,
        transition: theme.transitions.create('background-color'),
        '&:hover': {
          backgroundColor: '#ddd',
        },
      },
      collapseIcon: {
        height: '12px',
        width: '12px',
      },
    };
  },
  {
    name: 'gm-goal-tree-node',
  },
);
