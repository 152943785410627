import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import common from '@material-ui/core/colors/common';
import cyan from '@material-ui/core/colors/cyan';
import deepOrange from '@material-ui/core/colors/deepOrange';
import green from '@material-ui/core/colors/green';
import indigo from '@material-ui/core/colors/indigo';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import teal from '@material-ui/core/colors/teal';
import yellow from '@material-ui/core/colors/yellow';
import {
  createMuiTheme,
  MuiThemeProvider,
  StyleRules,
  withStyles,
} from '@material-ui/core/styles';
import {
  Theme,
  ThemeOptions,
} from '@material-ui/core/styles/createMuiTheme';
import {
  CSSProperties,
  WithStyles,
} from '@material-ui/core/styles/withStyles';
import * as React from 'react';

const fontSizes = {
  xsmall: 10,
  small: 12,
  base: 14,
  large: 16,
  xlarge: 18,
};

const halfOfMobileGoalHeight = 27;
const halfOfDeskTopGoalHeight = 40;
const halfOfConnectionWidth = 10;

export const goal = {
  connectionWidth: halfOfConnectionWidth * 2,
  halfOfConnectionWidth,
  mobileHeight: halfOfMobileGoalHeight * 2,
  desktopHeight: halfOfDeskTopGoalHeight * 2,
  mobileWidth: 96,
  desktopWidth: 150,
  halfOfMobileHeight: halfOfMobileGoalHeight,
  halfOfDeskTopHeight: halfOfDeskTopGoalHeight,
  marginVert: 4,
  connectionLine: '1px solid #00000026',
  menu: {
    width: 120,
    height: 110,
  },
};

export const appBar = {
  heigh: 65,
  mobileHeigh: 54,
};

export const themeCustomData = {
  custom: {
    appBar,
    goal,
    appTabs: {
      heighMobile: 45,
      heighDesktop: 75,
      widthDesktop: 160,
    },
    fontSizes,
    breakpoints: {
      // direction - up
      min: 321,
      mobileBig: 375,
      desktop: 800,
    },
  },
};

export type ExtTheme = Theme & typeof themeCustomData;

export type WithStylesAndTheme<ClassKey extends string> = WithStyles<
  ClassKey
> & { theme: ExtTheme };

/* global_classes */
export const globalClasses = {
  gcWhiteText: 'gcWhiteText',
  // gcGreyText: 'gcGreyText',
  // gcAlignSelfCenter: 'gcAlignSelfCenter',
  gcButtonIcon: 'gcButtonIcon',

  gcFontXsmall: 'gcFontXsmall',
  gcFontSmall: 'gcFontSmall',
  gcFontBase: 'gcFontBase',
  gcFontLarge: 'gcFontLarge',
  gcFontXlarge: 'gcFontXlarge',
};

const globalClassesStyles: StyleRules<keyof typeof globalClasses> = {
  gcWhiteText: {
    color: '#fff',
  },
  // gcGreyText: {
  //   color: grey[500],
  // },
  // gcAlignSelfCenter: {
  //   alignSelf: 'center',
  // },
  gcButtonIcon: {
    marginRight: '5px',
  },
  gcFontXsmall: {
    fontSize: fontSizes.xsmall + 'px',
  },
  gcFontSmall: {
    fontSize: fontSizes.small + 'px',
  },
  gcFontBase: {
    fontSize: fontSizes.base + 'px',
  },
  gcFontLarge: {
    fontSize: fontSizes.large + 'px',
  },
  gcFontXlarge: {
    fontSize: fontSizes.xlarge + 'px',
  },
};

export const colors = {
  primary: {
    light: cyan[400],
    main: cyan[600],
    dark: cyan[800],
  },
  secondary: {
    light: deepOrange[400],
    main: deepOrange[600],
    dark: deepOrange[800],
  },
  goal: {
    blue: blue[200],
    amber: amber[300],
    orange: orange[200],
    teal: teal[200],
    green: green[200],
    yellow: yellow[300],
    indigo: indigo[200],
    red: red[200],
    white: common.white,
    default: cyan[200],
  },
};

export const theme = createMuiTheme({
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
  },
  typography: {
    useNextVariants: true,
  },
  ...themeCustomData,
} as ThemeOptions);

export const styleCallback = () => {
  const result: Record<string, CSSProperties> = {};
  for (const key in globalClassesStyles) {
    if (globalClassesStyles.hasOwnProperty(key)) {
      // tslint:disable-next-line: no-any
      result['@global .' + key] = (globalClassesStyles as any)[key];
    }
  }
  return result;
};

export function applyTheme(Component: React.ComponentType) {
  return (props: object) => {
    const StyleComponent = withStyles(styleCallback)(Component);
    return (
      <MuiThemeProvider theme={theme}>
        <StyleComponent {...props} />
      </MuiThemeProvider>
    );
  };
}
