"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var styles_1 = require("@material-ui/styles");

exports.useStyles = styles_1.makeStyles(function (theme) {
  return {
    root: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center'
    },
    img: {
      objectFit: 'cover',
      maxHeight: '100%',
      maxWidth: '100%',
      width: '100%',
      height: '100%',
      transition: theme.transitions.create('opacity', {
        duration: '0.5s'
      })
    },
    imgHidden: {
      opacity: 0
    }
  };
}, {
  name: 'ca-lazy-load-image'
});