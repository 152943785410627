import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import {
  addGoal,
  changeAppTab,
  changeGoals,
  changeLocalSettings,
  deleteGoals,
  restoreBackup,
} from '../../redux-actions';

const gmActions = {
  addGoal,
  deleteGoals,
  changeGoals,
  changeAppTab,
  changeLocalSettings,
  restoreBackup,
};

export type GmActions = typeof gmActions;

// tslint:disable-next-line: no-any
export default function useGmDispatch(): [GmActions, Dispatch<any>] {
  return [gmActions, useDispatch()];
}
