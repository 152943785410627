// tslint:disable:max-line-length
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

export const HammerIcon: React.SFC<SvgIconProps> = (props) => (
  <SvgIcon {...props} viewBox="-45 -30 272 252">
    <path
      d="M192.035,69.824c0,3.15-1.228,6.111-3.456,8.339l-19.151,19.152c-2.229,2.229-5.19,3.454-8.339,3.454
	s-6.109-1.226-8.337-3.452l-18.971-18.971L32.306,179.822c-2.62,2.62-6.104,4.064-9.812,4.064c-3.706,0-7.19-1.444-9.812-4.064
	l-8.619-8.619C1.443,168.582,0,165.098,0,161.391c0-3.707,1.443-7.191,4.064-9.813L105.539,50.104l-18.97-18.97
	c-4.596-4.597-4.597-12.077-0.001-16.675l2.858-2.857c2.363-2.366,5.566-3.608,8.936-3.438l34.347,1.749
	c2.927,0.15,5.675,1.373,7.741,3.442l48.131,48.13C190.808,63.712,192.035,66.673,192.035,69.824z"
    />
  </SvgIcon>
);
