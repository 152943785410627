import auth from '../core/auth';
import {
  CHANGE_LOCAL_SETTINGS,
  ChangeLocalSettings,
} from '../redux-actions';
import { AppPage, StoreState } from '../types';

type State = StoreState['localSettings'];

const initialValue: State = {
  appPage: auth.authorized ? AppPage.main : AppPage.welcome,
};

export function localSettings(
  state: State = initialValue,
  action: ChangeLocalSettings,
): State {
  switch (action.type) {
    case CHANGE_LOCAL_SETTINGS:
      return {
        ...state,
        ...action.changes,
      };
    default:
      return state;
  }
}
