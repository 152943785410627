import { makeStyles } from '@material-ui/styles';
import { appBar, ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const {
      custom: { appTabs, breakpoints },
    } = theme;
    return {
      root: {
        overflowX: 'auto',
        overflowY: 'scroll',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: appTabs.heighMobile,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          bottom: 0,
        },
        backgroundColor: theme.palette.background.default,
      },
      contentContainer: {
        display: 'flex',
        minHeight: `calc(100% - ${appBar.heigh}px)`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          marginLeft: `${appTabs.widthDesktop}px`,
          minWidth: `calc(100% - ${appTabs.widthDesktop}px)`,
        },
      },
    };
  },
  {
    name: 'gm-root',
  },
);
