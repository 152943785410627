import { makeStyles } from '@material-ui/styles';
import { ExtTheme } from '../../shared/theme';

export const useStyles = makeStyles(
  (theme: ExtTheme) => {
    const { goal, breakpoints } = theme.custom;
    return {
      root: {
        padding: `5px ${goal.menu.width}px 30px 10px`,
        position: 'relative',
      },
      goalNodeRoot: {
        display: 'flex',
      },
      goalNodeChildren: {
        display: 'flex',
        flexDirection: 'column',
      },
      goalRoot: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid grey',
        height: '40px',
        width: '80px',
      },
      button: {
        marginTop: `${goal.marginVert}px`,
        width: `${goal.mobileWidth}px`,
        height: `${goal.mobileHeight}px`,
        [theme.breakpoints.up(breakpoints.desktop)]: {
          width: `${goal.desktopWidth}px`,
          height: `${goal.desktopHeight}px`,
        },
      },
      attentionButton: {
        display: 'block',
        lineHeight: '1.4',
      },
      snackBarContentRoot: {
        flexWrap: 'nowrap',
      },
      hintButton: {
        position: 'fixed',
        backgroundColor: '#fff',
        bottom: '60px',
        right: '15px',
        height: '40px',
        width: '40px',
        boxShadow: theme.shadows[15],
        [theme.breakpoints.up(breakpoints.desktop)]: {
          bottom: '30px',
          right: '40px',
        },
      },
      hintIcon: {
        height: '40px',
        width: '40px',
      },
    };
  },
  {
    name: 'gm-goal-tree',
  },
);

export type GmGoalTreeClasses = ReturnType<typeof useStyles>;
