import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { csn } from 'ca-shared';
import React, { Dispatch } from 'react';
import { globalClasses as gc } from '../../shared/theme';
import { Goal } from '../../shared/types';
import gh from '../../shared/utils/goal-helper';
import { GmActions } from '../custom-hooks/use-gm-dispatch';
import { Classes } from './gm-edit-goal.styles';

export function getActions({
  goal,
  classes,
  completed,
  changeGoals,
  addGoal,
  dispatch,
  handleClose,
  handleDeleteClick,
  onMoveToClick,
}: {
  goal: Goal;
  classes: Classes;
  completed: boolean;
  changeGoals: GmActions['changeGoals'];
  addGoal: GmActions['addGoal'];
  dispatch: Dispatch<any>; // tslint:disable-line: no-any
  handleClose: () => void;
  handleDeleteClick: (event: React.MouseEvent<HTMLElement>) => void;
  onMoveToClick: (goal: Goal) => void;
}) {
  return (
    <div className={classes.actionsArea}>
      <div className={classes.actionButtonRow}>
        <Button
          classes={{
            root: csn(classes.button, classes.actionButton),
            label: classes.actionButtonLabel,
          }}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(
              addGoal(
                gh.createGoal({
                  parentId: goal.id,
                  name: goal.name + ' subgoal',
                  color: goal.color,
                }),
              ),
            );
            handleClose();
          }}>
          <AddIcon />
          Add subgoal
        </Button>

        <Button
          classes={{
            root: csn(classes.button, classes.actionButton),
            label: classes.actionButtonLabel,
          }}
          variant="outlined"
          color="primary"
          onClick={handleDeleteClick}>
          <DeleteIcon />
          Delete
        </Button>
      </div>

      <div className={classes.actionButtonRow}>
        <Button
          classes={{
            root: csn(classes.button, classes.actionButton),
            label: classes.actionButtonLabel,
          }}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(
              changeGoals([
                {
                  id: goal.id,
                  complete: !goal.complete,
                },
              ]),
            );
            handleClose();
          }}>
          {completed ? (
            <TrendingUpIcon className={gc.gcButtonIcon} />
          ) : (
            <CheckIcon className={gc.gcButtonIcon} />
          )}
          {completed ? 'Set in progress' : 'Complete'}
        </Button>

        <Button
          classes={{
            root: csn(classes.button, classes.actionButton),
            label: classes.actionButtonLabel,
          }}
          variant="outlined"
          color="primary"
          onClick={() => {
            onMoveToClick(goal);
            handleClose();
          }}>
          <SubdirectoryArrowRightIcon />
          Move to ...
        </Button>
      </div>
    </div>
  );
}
