import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';
import PlusIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/InfoRounded';
import { CaInfoButton, csn } from 'ca-shared';
import React, { useCallback, useRef, useState } from 'react';
import { colors, globalClasses as gc } from '../../shared/theme';
import { Goal, GoalTreeNode } from '../../shared/types';
import gh from '../../shared/utils/goal-helper';
import { logRender } from '../../shared/utils/log.utils';
import {
  useGmDispatch,
  useGmIsMobile,
  useGmSelector,
} from '../custom-hooks';
import { GmConfirmationDialog } from '../gm-confirmation-dialog';
import { GmEditGoal } from '../gm-edit-goal';
import GmGoalTreeNode from './gm-goal-tree-node';
import { getExampleGoals } from './gm-goal-tree.example';
import { useStyles } from './gm-goal-tree.styles';

const predefinedColors = Object.values(colors.goal).map(
  (color) => color,
);

export const GmGoalTreeView: React.FC = () => {
  logRender(GmGoalTreeView);

  const goals = useGmSelector(
    (storeState) => storeState.userData.goals,
  );
  const [{ addGoal, changeGoals }, dispatch] = useGmDispatch();
  const classes = useStyles();
  const [editingGoalId, setEditingGoalId] = useState(
    null as Goal['id'] | null,
  );
  const [editingAnchor, setEditingAnchor] = useState(
    null as HTMLElement | null,
  );
  const [pendingGoal, setPendingGoal] = useState(null as Goal | null);

  const [badActionText, setBadActionText] = useState('');
  const [colorIndex, setColorIndex] = useState(0);

  const { current: refs } = useRef({
    pendingGoal: null as Goal | null,
    prevGoals: goals,
    rootNode: null as GoalTreeNode | null,
  });

  const screenCenterRef = useRef(null);

  const handleCloseBadActionDial = useCallback(
    () => setBadActionText(''),
    [],
  );

  const setParentGoal = (parent: Goal) => {
    if (pendingGoal) {
      dispatch(
        changeGoals([
          {
            id: pendingGoal.id,
            parentId: parent.id,
          },
        ]),
      );
      setPendingGoal(null);
    }
  };

  if (pendingGoal && refs.pendingGoal !== pendingGoal) {
    // cache to avoid blank text on snackbar closing
    refs.pendingGoal = pendingGoal;
  }

  if (refs.prevGoals !== goals || !refs.rootNode) {
    refs.prevGoals = goals;
    refs.rootNode = gh.buildGoalTree(gh.rootGoal, goals);
  }

  const handleGoalClick = useCallback(
    (goalId: Goal['id'], anchor: HTMLElement) => {
      if (pendingGoal) {
        if (goalId === pendingGoal.id) {
          setBadActionText('Goal cannot be a parent to itself !');
          return;
        }
        const subgoals = gh.getSubgoals({
          goal: pendingGoal,
          goals,
          deep: true,
        });
        const subgoal = gh.findGoal(subgoals, goalId);
        if (subgoal) {
          setBadActionText('Subgoal cannot be a parent to a goal !');
          return;
        }
        const goal = gh.findGoal(goals, goalId);
        if (goal) {
          setParentGoal(goal);
        }
      } else {
        setEditingGoalId(goalId);
        setEditingAnchor(anchor);
      }
    },
    [pendingGoal, goals],
  );

  const isMobile = useGmIsMobile();

  return (
    <div className={classes.root}>
      <GmGoalTreeNode
        goal={refs.rootNode.goal}
        children={refs.rootNode.children}
        isRoot={true}
        isFirst={true}
        isLast={true}
        onGoalClick={handleGoalClick}
      />

      {Boolean(pendingGoal && pendingGoal.parentId !== gh.rootId) && (
        <Button
          className={csn(classes.button, classes.attentionButton)}
          onClick={() => {
            setParentGoal(gh.rootGoal);
          }}
          color="secondary"
          variant="contained">
          No Parent
        </Button>
      )}

      {Boolean(!pendingGoal) && (
        <Button
          className={classes.button}
          onClick={() => {
            setColorIndex(
              colorIndex >= predefinedColors.length - 1
                ? 0
                : colorIndex + 1,
            );
            dispatch(
              addGoal(
                gh.createGoal({
                  color: predefinedColors[colorIndex],
                }),
              ),
            );
          }}
          color="primary"
          variant="outlined">
          <PlusIcon className={gc.gcButtonIcon} />
          Add
        </Button>
      )}

      {Boolean(!goals.length) && (
        <Button
          className={csn(classes.button, classes.attentionButton)}
          onClick={() => {
            getExampleGoals().forEach((iter) =>
              dispatch(addGoal(iter)),
            );
          }}
          color="secondary"
          variant="contained">
          {/* <PlusIcon className={gc.gcButtonIcon} /> */}
          Load example
        </Button>
      )}

      {
        // now, that the awesome fucking solution )
        <div
          ref={screenCenterRef}
          style={{ position: 'fixed', left: '50%', top: '50%' }}
        />
      }

      <GmEditGoal
        // show dialog on center for mobile
        anchor={
          editingAnchor &&
          (isMobile ? screenCenterRef.current : editingAnchor)
        }
        goalId={editingGoalId}
        onClose={() => setEditingAnchor(null)}
        onMoveToClick={(goal) => setPendingGoal({ ...goal })}
      />

      <CaInfoButton
        className={classes.hintButton}
        buttonContent={
          <InfoIcon color="primary" className={classes.hintIcon} />
        }
        popupContent={
          <Typography>
            This page is designed to help to{' '}
            <b>build a clear picture</b> of what you are currently in
            pursuit of <br />
            and to help <b>visualize</b> what <b>steps</b> and in
            which order have to be taken to achieve every single goal.
            <br />
            <br />- Page displays <b>all</b> the <b>goals</b>.<br />
            - Goal branches can be collapsed/expanded.
            <br /> - Ability to filter/hide goals is in development
            and will be available soon.
            <br />
            <br />
          </Typography>
        }
      />

      <Snackbar
        ContentProps={{
          className: classes.snackBarContentRoot,
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={Boolean(pendingGoal)}
        message={
          Boolean(refs.pendingGoal) && (
            <span>
              {`Select a new parent goal for "${
                (refs.pendingGoal as Goal).name
              }"`}
            </span>
          )
        }
        action={
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => {
              setPendingGoal(null);
              setBadActionText('');
            }}>
            Cancel
          </Button>
        }
      />

      <GmConfirmationDialog
        title={badActionText}
        open={Boolean(badActionText)}
        cancelButtonText="OK"
        hideConfirm={true}
        onClose={handleCloseBadActionDial}
      />
    </div>
  );
};
