import { debounceTime, filter, switchMap } from 'rxjs/operators';
import {
  setError,
  SYNC_SETTINGS,
  syncUserDataComplete,
} from '../redux-actions';
import { GmEpic, UserData } from '../types';
import { apiPost, authFilter } from '../utils/request.utils';

export const syncUserDataEpic: GmEpic = (action$, $state) =>
  action$.ofType(SYNC_SETTINGS).pipe(
    filter(authFilter()),
    debounceTime(500),
    switchMap(() =>
      apiPost('userData', $state.value.userData)(
        (userData) => syncUserDataComplete(userData as UserData),
        (error) => setError(error),
      ),
    ),
  );
