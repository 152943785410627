"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var styles_1 = require("@material-ui/styles");

exports.useStyles = styles_1.makeStyles(function (theme) {
  return {
    root: {},
    avatar: {
      height: '36px',
      width: '36px',
      borderRadius: '50%',
      overflow: 'hidden'
    },
    userMenuContent: {
      display: 'flex',
      flexDirection: 'column'
    },
    userInfoInMenu: {
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid ' + theme.palette.grey[300]
    },
    avatarInMenu: {
      marginRight: '10px'
    }
  };
}, {
  name: 'ca-login'
});