import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ExtTheme } from '../../shared/theme';

export default function useGmDispatch(): boolean {
  const {
    breakpoints,
    custom: { breakpoints: gmBreakpoints },
  } = useTheme<ExtTheme>();
  return useMediaQuery(breakpoints.down(gmBreakpoints.desktop));
}
