import React from 'react';
import ReactDOM from 'react-dom';
import './assets';
import GmApp from './components/gm-app/gm-app';
import * as serviceWorker from './serviceWorker';
import { applyTheme } from './shared/theme';

const ThemedApp = applyTheme(GmApp);

ReactDOM.render(<ThemedApp />, document.getElementById('root'));

serviceWorker.unregister();
