import { CHANGE_APP_TAB, ChangeAppTab } from '../redux-actions';
import { AppTab } from '../types';

type State = AppTab;

const initialValue: State = AppTab.craft;

export function selectedAppTab(
  state: State = initialValue,
  action: ChangeAppTab,
): State {
  switch (action.type) {
    case CHANGE_APP_TAB:
      return action.appTab;
    default:
      return state;
  }
}
