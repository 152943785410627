import Typography from '@material-ui/core/Typography';
import { TypographyProps } from '@material-ui/core/Typography';
import * as React from 'react';
import {
  globalClasses,
} from '../../theme';

interface Props extends TypographyProps {
  custom?: {
    // color?: 'grey' | 'white';
    color?: 'white';
    size?: 'xsmall' | 'small' | 'base' | 'large' | 'xlarge'
  };
}

const colorClasses = {
  // grey: globalClasses.gcGreyText,
  white: globalClasses.gcWhiteText,
};

const fontSizeClasses = {
  xsmall: globalClasses.gcFontXsmall,
  small: globalClasses.gcFontSmall,
  base: globalClasses.gcFontBase,
  large: globalClasses.gcFontLarge,
  xlarge: globalClasses.gcFontXlarge,
};

export class GmTextView extends React.PureComponent<Props> {

  public render(): JSX.Element {
    const { custom, className, ...typographyProps } = this.props;
    let classes = className;
    if (custom) {
      if (custom.color) {
        classes += ' ' + colorClasses[custom.color];
      }
      if (custom.size) {
        classes += ' ' + fontSizeClasses[custom.size];
      }
    }

    return (
      <Typography
        className={classes}
        {...typographyProps}
      />
    );
  }
}
