import { applyMiddleware, compose, createStore } from 'redux';
import { localStorageSaver } from '../core/local-storage-saver';
import { epicMiddleware, rootEpic } from '../middleware';
import { rootReducer } from '../redux-reducers';

const composeEnhancers =
  typeof window === 'object' &&
  // tslint:disable-next-line: no-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? // tslint:disable-next-line: no-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(epicMiddleware),
    localStorageSaver,
  ),
);

epicMiddleware.run(rootEpic);

export default store;
