"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

function csn() {
  var classes = [];

  for (var _i = 0; _i < arguments.length; _i++) {
    classes[_i] = arguments[_i];
  }

  return classes.map(function (item) {
    if (item) {
      if (typeof item === 'object') {
        if (Array.isArray(item)) {
          if (item[0] && item[1]) {
            return item[0];
          }
        } else {
          return Object.keys(item).filter(function (key) {
            return item[key];
          }).join(' ');
        }
      } else {
        return item;
      }
    }

    return '';
  }).filter(function (item) {
    return item;
  }).join(' ');
}

exports.csn = csn;