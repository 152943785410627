import { Reducer, Store, StoreEnhancerStoreCreator } from 'redux';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StoreState } from '../types/store-state.types';
import {
  loadReduxState,
  removeReduxState,
  saveReduxState,
} from '../utils/local-storage.utils';
import auth from './auth';

type SliceConfigBase<T> = {
  [key in keyof T]?: boolean | SliceConfigBase<T[key]>;
};
type SliceConfig = SliceConfigBase<StoreState>;

const sliceConfig: SliceConfig = {
  userData: true,
  selectedAppTab: true,
  localSettings: true,
};

// tslint:disable: no-any
function deepSlice(source: any, config: SliceConfig | boolean): any {
  if (typeof source === 'object' && typeof config === 'object') {
    return Object.keys(config).reduce(
      (
        acc: {
          [key: string]: any;
        },
        key,
      ) => {
        acc[key] = deepSlice(source[key], (config as any)[key]);
        return acc;
      },
      {},
    );
  } else {
    return config ? source : undefined;
  }
}

function deepMerge(state: any, fromStorage: any): any {
  if (!Array.isArray(state) && typeof state === 'object') {
    if (typeof fromStorage !== 'object') {
      return state;
    }

    return Object.keys(state).reduce(
      (
        acc: {
          [key: string]: any;
        },
        key,
      ) => {
        acc[key] = deepMerge(state[key], fromStorage[key]);
        return acc;
      },
      {},
    );
  } else {
    return fromStorage !== undefined ? fromStorage : state;
  }
}

export const localStorageSaver = (
  next: StoreEnhancerStoreCreator<StoreState>,
) => {
  return (reducer: Reducer<StoreState>, initialState: StoreState) => {
    if (!initialState) {
      initialState = reducer({} as any, {
        type: '',
      });
    }

    initialState = deepMerge(
      initialState,
      JSON.parse(loadReduxState(auth.email) || '{}'),
    );
    const store: Store<StoreState> = next(reducer, initialState);

    const sub = new Subject();
    store.subscribe(() => {
      if (store.getState().userData.newUser) {
        // migrating user data
        const reduxState = loadReduxState('');
        if (reduxState) {
          saveReduxState(auth.email, reduxState);
          removeReduxState('');
        }
        window.location.reload();
      } else {
        sub.next();
      }
    });

    sub.pipe(debounceTime(500)).subscribe(() => {
      saveReduxState(
        auth.email,
        JSON.stringify(deepSlice(store.getState(), sliceConfig)),
      );
    });

    return store;
  };
};
