import Button, { ButtonProps } from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import { csn } from 'ca-shared';
import React, { useState } from 'react';
import { colors } from '../../../shared/theme';
import { useStyles } from './gm-color-selection.styles';

export const predefinedColors = Object.entries(
  colors.goal,
).map(([name, color]) => ({ name, color }));

interface Props extends ButtonProps {
  text: string;
  selectedColor?: string;
  onSelectColor: (color: string) => void;
}

export const GmColorSelectionView: React.FC<Props> = ({
  className,
  text,
  selectedColor,
  onSelectColor,
  style,
  ...restProps
}) => {
  const classes = useStyles();

  const [anchor, setAnchor] = useState(null as HTMLElement | null);

  return (
    <>
      <Button
        className={csn(classes.button, className)}
        style={{
          ...style,
          backgroundColor: selectedColor,
        }}
        onClick={(event) => setAnchor(event.currentTarget)}
        {...restProps}>
        {text}
      </Button>

      <Popover
        anchorEl={anchor}
        classes={{
          paper: classes.popoverPaper,
        }}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        onClick={(event) => event.stopPropagation()}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}>
        <div>
          {predefinedColors.map((iter, index) => {
            return (
              <Button
                key={iter.name}
                className={classes.color}
                style={{
                  backgroundColor: iter.color,
                }}
                onClick={() => {
                  onSelectColor(iter.color);
                  setAnchor(null);
                }}>
                {iter.name}
              </Button>
            );
          })}
        </div>
      </Popover>
    </>
  );
};
