import auth from '../core/auth';
import store from '../core/redux-store';
import {
  // changeLocalUserData,
  syncUserData,
} from '../redux-actions';

// interface BeforeInstallPromptEvent extends Event {
//   readonly platforms: Array<string>;
//   readonly userChoice: Promise<{
//     outcome: 'accepted' | 'dismissed',
//     platform: string,
//   }>;
//   prompt(): Promise<void>;
// }

class WindowEventHandler {
  // private deferredPrompt?: BeforeInstallPromptEvent;

  constructor() {
    window.addEventListener('focus', this.onFocus, {
      capture: true,
      passive: true,
    });
    window.addEventListener('load', this.onLoad, {
      capture: true,
      passive: true,
    });
    // window.addEventListener('beforeinstallprompt', this.onBeforeInstallPrompt);
  }

  // public showInstallPrompt() {
  //   if (this.deferredPrompt) {
  //     this.deferredPrompt.prompt();
  //     // bug in chrome userChoice is not resolved when install button click
  //     // so temporary we hide button here. move this into userChoice callback after bug fix.
  //     store.dispatch(changeLocalUserData({canInstallApp: false}));
  //     this.deferredPrompt.userChoice.then((choiceResult) => {
  //       // store.dispatch(changeLocalUserData({canInstallApp: false}));
  //       this.deferredPrompt = undefined;
  //     });
  //   }
  // }

  private onFocus = (event: FocusEvent) => {
    if (event.target === window) {
      store.dispatch(syncUserData());
    }
  };

  private onLoad = async (event: Event) => {
    const token = await auth.getToken();
    if (token) {
      store.dispatch(syncUserData());
    }
  };

  // private onBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
  //   event.preventDefault();
  //   if (!window.matchMedia('(display-mode: standalone)').matches) {
  //     this.deferredPrompt = event;
  //     store.dispatch(changeLocalUserData({canInstallApp: true}));
  //   }
  // }
}

export const windowEventHandler = new WindowEventHandler();
