import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import {
  CaInfoButton,
  CaNumberTextField,
  csn,
  dateUtils as du,
} from 'ca-shared';
import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import gh from '../../shared/utils/goal-helper';
import { logRender } from '../../shared/utils/log.utils';
import { AppTab } from '../../types';
import { useGmDispatch } from '../custom-hooks';
import {
  examples,
  maxDuration,
  minDuration,
  steps,
} from './gm-craft-wizard.const';
import { Classes, useStyles } from './gm-craft-wizard.styles';

export const GmCraftWizardView: React.FC = () => {
  logRender(GmCraftWizardView);

  const classes = useStyles();

  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [duration, setDuration] = useState(30);
  const [pendingDuration, setPendingDuration] = useState('30');
  const [milestoneName, setMilestoneName] = useState('');
  const [milestoneDesc, setMilestoneDesc] = useState('');
  const [firstStepName, setFirstStepName] = useState('');
  const [firstStepDesc, setFirstStepDesc] = useState('');

  const [{ addGoal, changeAppTab }, dispatch] = useGmDispatch();

  const handleDurationClick = (durationValue: number) => {
    setDuration(durationValue);
    setPendingDuration(durationValue.toString());
    setStep(2);
  };

  const handleNext = () => {
    if (step >= steps.length - 1) {
      handleSubmit();
    } else {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step) {
      setStep(step - 1);
    }
  };

  const handleSubmit = () => {
    const goal = gh.createGoal({
      name,
      description: desc,
      date: du.getFormattedDate(
        du.addDays(du.getCurrentDate(), duration),
      ),
    });

    const milestoneGoal = gh.createGoal({
      name: milestoneName,
      description: milestoneDesc,
      parentId: goal.id,
    });

    const firstStepGoal = gh.createGoal({
      name: firstStepName,
      description: firstStepDesc,
      date: du.getFormattedDate(du.getCurrentDate()),
      parentId: milestoneGoal.id,
    });

    dispatch(addGoal(goal));
    dispatch(addGoal(milestoneGoal));
    dispatch(addGoal(firstStepGoal));
    dispatch(changeAppTab(AppTab.all));
  };

  let canGoNext;
  switch (step) {
    case 0:
      canGoNext = Boolean(name.trim());
      break;
    case 1:
      canGoNext = Boolean(duration && duration >= minDuration);
      break;
    case 2:
      canGoNext = Boolean(milestoneName.trim());
      break;
    case 3:
      canGoNext = Boolean(firstStepName.trim());
      break;
    default:
      break;
  }
  // canGoNext = true;

  return (
    <div className={classes.root}>
      <Paper className={classes.contentContainer}>
        <div className={classes.content}>
          <SwipeableViews index={step} disabled={true}>
            {renderGoalStep({
              classes,
              name,
              setName,
              description: desc,
              setDescription: setDesc,
              title: 'Define Your Goal',
              hintContent: (onClose) => (
                <Typography>
                  Try to be <b> as precise as possible.</b> <br />
                  <br /> Do not set vague goals like "Read more",
                  "Earn more money", "Start doing exercises" e.t c
                  <br />
                  There should be criteria that define if the goal is
                  achieved or not. <br />
                  <br />
                  Examples:
                  <br />
                  {examples.goals.map((iter) => (
                    <Button
                      key={iter.name}
                      className={classes.exampleButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setName(iter.name);
                        setDesc(iter.desc);
                        onClose();
                      }}>
                      {iter.text}
                    </Button>
                  ))}
                </Typography>
              ),
            })}

            <div className={classes.stepContent}>
              <div className={classes.stepTitleContainer}>
                <Typography variant="h6" align="center">
                  Define the Deadline
                </Typography>
                <CaInfoButton
                  className={classes.stepTitleHintButton}
                  buttonContent={
                    <InfoIcon
                      className={classes.stepTitleHintButtonIcon}
                    />
                  }
                  popupContent={
                    <Typography>
                      Set the time limit, by which an objective must
                      be accomplished
                      <br />
                      <br />
                      Minimum 3 days <br />
                      (otherwise it's not much of a goal)
                    </Typography>
                  }
                />
              </div>
              <div
                className={csn(
                  classes.durationControlRow,
                  classes.durationFirstRowMargin,
                )}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.durationControl}
                  onClick={() => handleDurationClick(7)}>
                  Week
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.durationControl}
                  onClick={() => handleDurationClick(30)}>
                  Month
                </Button>
              </div>
              <div className={classes.durationControlRow}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.durationControl}
                  onClick={() => handleDurationClick(30)}>
                  100 days
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.durationControl}
                  onClick={() => handleDurationClick(365)}>
                  Year
                </Button>
              </div>

              <div className={classes.durationControlRow}>
                <div className={classes.durationControl} />
                <CaNumberTextField
                  className={classes.durationControl}
                  value={pendingDuration}
                  variant="outlined"
                  InputProps={{
                    inputProps: {
                      integerPartLength: 3,
                      decimalPartLength: 0,
                      max: maxDuration,
                      min: minDuration,
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        days
                      </InputAdornment>
                    ),
                    onFocus: (
                      event: React.FocusEvent<HTMLInputElement>,
                    ) => event.currentTarget.select(),
                  }}
                  onChange={(event) => {
                    const value = event.target.value;
                    setPendingDuration(value);
                    if (value.trim()) {
                      setDuration(parseFloat(value));
                    } else {
                      setDuration(0);
                    }
                  }}
                />
              </div>

              <br />
              {duration < minDuration && (
                <Typography variant="body2" align="center">
                  Minimum 3 days
                </Typography>
              )}
            </div>

            {renderGoalStep({
              classes,
              name: milestoneName,
              setName: setMilestoneName,
              description: milestoneDesc,
              setDescription: setMilestoneDesc,
              title: 'Define Your First Milestone',
              hintContent: (onClose) => (
                <Typography>
                  Just like with the goal, try to be{' '}
                  <b> as precise as possible.</b> <br />
                  <br /> Do not set vague goals like "Read a few
                  chapters of War and Peace", "Earn some money", "Do
                  push-ups" e.t c.
                  <br />
                  There should be criteria that define if the
                  milestone is achieved or not. <br />
                  <br />
                  Examples:
                  {examples.milestones.map((iter) => (
                    <Button
                      key={iter.name}
                      className={classes.exampleButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setMilestoneName(iter.name);
                        setMilestoneDesc(iter.desc);
                        onClose();
                      }}>
                      {iter.text}
                    </Button>
                  ))}
                </Typography>
              ),
            })}

            {renderGoalStep({
              classes,
              name: firstStepName,
              setName: setFirstStepName,
              description: firstStepDesc,
              setDescription: setFirstStepDesc,
              title: 'What can be done today ?',
              hintContent: (onClose) => (
                <Typography>
                  Define your first step.
                  <b>
                    {' '}
                    Something that you can finish till the end of this
                    day
                  </b>
                  <br />
                  Well, you know the drill. Try to be
                  <b> as precise as possible.</b>
                  <br /> Do not set vague goals like "Read a few pages
                  of War and Peace", "Earn a few bucks", "Do some
                  push-ups" e.t c
                  <br />
                  Examples of properly defined steps:
                  {examples.firstSteps.map((iter) => (
                    <Button
                      key={iter.name}
                      className={classes.exampleButton}
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setFirstStepName(iter.name);
                        setFirstStepDesc(iter.desc);
                        onClose();
                      }}>
                      {iter.text}
                    </Button>
                  ))}
                </Typography>
              ),
            })}
          </SwipeableViews>
        </div>
        <Stepper
          className={classes.stepperRoot}
          activeStep={step}
          alternativeLabel={true}>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel
                classes={{
                  iconContainer: classes.stepLabelIconContainer,
                }}>
                {steps[index]}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        <div className={classes.actionArea}>
          <Button color="primary" onClick={handleBack}>
            Back
          </Button>
          <Button
            color="primary"
            onClick={handleNext}
            disabled={!canGoNext}>
            {step < steps.length - 1 ? 'Next' : 'Finish'}
          </Button>
        </div>
      </Paper>
    </div>
  );
};

function renderGoalStep({
  classes,
  name,
  setName,
  description,
  setDescription,
  title,
  hintContent,
}: {
  classes: Classes;
  name: string;
  setName: (name: string) => void;
  description: string;
  setDescription: (description: string) => void;
  title: string;
  hintContent: (onClose: () => void) => JSX.Element;
}) {
  return (
    <div className={classes.stepContent}>
      <div className={classes.stepTitleContainer}>
        <Typography variant="h6" align="center">
          {title}
        </Typography>
        <CaInfoButton
          className={classes.stepTitleHintButton}
          buttonContent={
            <InfoIcon className={classes.stepTitleHintButtonIcon} />
          }
          popupContent={hintContent}
        />
      </div>
      <TextField
        className={classes.input}
        label="Goal"
        variant="outlined"
        value={name}
        onFocus={(event) => {
          event.currentTarget.select();
        }}
        onChange={(event) => setName(event.currentTarget.value)}
      />
      <TextField
        className={classes.input}
        label="Description"
        variant="outlined"
        multiline={true}
        rowsMax={5}
        value={description}
        onFocus={(event) => {
          event.currentTarget.select();
        }}
        onChange={(event) =>
          setDescription(event.currentTarget.value)
        }
      />
      <br />
      <Typography variant="body2" align="center">
        Try to be as precise as possible.
      </Typography>
    </div>
  );
}
