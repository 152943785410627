import { Action } from 'redux';
import { Goal } from '../shared/types';
import { UserData } from '../types';

export interface ShouldSaveUserData {
  saveUserData: true;
}

export const DELETE_GOALS = 'DELETE_GOALS';
export interface DeleteGoal extends Action, ShouldSaveUserData {
  type: typeof DELETE_GOALS;
  goalIds: Array<Goal['id']>;
}
export function deleteGoals(
  goalIds: DeleteGoal['goalIds'],
): DeleteGoal {
  return {
    type: DELETE_GOALS,
    goalIds,
    saveUserData: true,
  };
}

export const ADD_GOAL = 'ADD_GOAL';
export interface AddGoal extends Action, ShouldSaveUserData {
  type: typeof ADD_GOAL;
  goal: Goal;
}
export function addGoal(goal: Goal): AddGoal {
  return {
    type: ADD_GOAL,
    goal,
    saveUserData: true,
  };
}

export const CHANGE_GOALS = 'CHANGE_GOALS';
export interface ChangeGoals extends Action, ShouldSaveUserData {
  type: typeof CHANGE_GOALS;
  changes: Array<{ id: Goal['id'] } & Partial<Goal>>;
}
export function changeGoals(
  changes: ChangeGoals['changes'],
): ChangeGoals {
  return {
    type: CHANGE_GOALS,
    changes,
    saveUserData: true,
  };
}

export const SYNC_SETTINGS = 'SYNC_SETTINGS';
export interface SyncUserData {
  type: typeof SYNC_SETTINGS;
}

export function syncUserData(): SyncUserData {
  return {
    type: SYNC_SETTINGS,
  };
}

export const SYNC_USER_DATA_COMPLETE = 'SYNC_USER_DATA_COMPLETE';
export interface SyncUserDataComplete {
  type: typeof SYNC_USER_DATA_COMPLETE;
  userData: UserData;
}

export function syncUserDataComplete(
  userData: UserData,
): SyncUserDataComplete {
  return {
    type: SYNC_USER_DATA_COMPLETE,
    userData,
  };
}

export const RESTORE_BACKUP = 'RESTORE_BACKUP';
export interface RestoreBackup extends Action, ShouldSaveUserData {
  type: typeof RESTORE_BACKUP;
  goals: Array<Goal>;
}

export function restoreBackup(goals: Array<Goal>): RestoreBackup {
  return {
    type: RESTORE_BACKUP,
    goals,
    saveUserData: true,
  };
}
