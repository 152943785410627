"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var dateFns = __importStar(require("date-fns"));

exports.DEFAULT_DATE_FORMAT = 'yyyy-MM-dd';

var DateUtils =
/** @class */
function () {
  function DateUtils() {
    this.coerceToDate = function (date) {
      switch (typeof date) {
        case 'string':
          return new Date(date);

        case 'number':
          return new Date(date);

        default:
          return date;
      }
    };
  }

  DateUtils.prototype.yearRange = function (date) {
    return [dateFns.startOfYear(date), dateFns.endOfYear(date)];
  };

  DateUtils.prototype.getYear = function (date) {
    return dateFns.getYear(date);
  };

  DateUtils.prototype.getMonth = function (date) {
    return dateFns.getMonth(date);
  };

  DateUtils.prototype.isDateLocked = function (date, unlockedDays) {
    return this.isBefore(this.getStartOfDay(this.getDate(date)), this.getStartOfDay(new Date())) && !unlockedDays.some(function (uDate) {
      return uDate === date;
    });
  };

  DateUtils.prototype.timestampToIsoString = function (timestampInSeconds, dateFormat) {
    if (dateFormat === void 0) {
      dateFormat = exports.DEFAULT_DATE_FORMAT;
    }

    return this.getFormattedDate(new Date(timestampInSeconds * 1000), dateFormat);
  };

  DateUtils.prototype.getUnixTime = function (date) {
    return dateFns.getUnixTime(date);
  };

  DateUtils.prototype.getCurrentUnixTime = function () {
    return this.getUnixTime(new Date());
  };

  DateUtils.prototype.getTimestampInMilliseconds = function (date) {
    return date.valueOf();
  };
  /* istanbul ignore next */


  DateUtils.prototype.getCurrentDate = function () {
    return new Date();
  };

  DateUtils.prototype.getDate = function (date) {
    return new Date(date);
  };

  DateUtils.prototype.createDate = function (year, month, day) {
    if (month === void 0) {
      month = 1;
    }

    if (day === void 0) {
      day = 1;
    }

    return new Date(year, month - 1, day);
  };

  DateUtils.prototype.getFormattedDate = function (date, dateFormat) {
    if (dateFormat === void 0) {
      dateFormat = exports.DEFAULT_DATE_FORMAT;
    }

    return dateFns.format(this.coerceToDate(date), dateFormat);
  };

  DateUtils.prototype.getHours = function (date) {
    return dateFns.getHours(date);
  };

  DateUtils.prototype.getMinutes = function (date) {
    return dateFns.getMinutes(date);
  };

  DateUtils.prototype.getStartOfDay = function (date) {
    return dateFns.startOfDay(date);
  };

  DateUtils.prototype.getMealTime = function (time) {
    return this.getHours(time) * 60 + this.getMinutes(time);
  };

  DateUtils.prototype.getStartOfToday = function () {
    return this.getStartOfDay(this.getCurrentDate());
  };

  DateUtils.prototype.getStartOfWeek = function (date) {
    return dateFns.startOfWeek(date);
  };

  DateUtils.prototype.getStartOfMonth = function (date) {
    return dateFns.startOfMonth(date);
  };

  DateUtils.prototype.getStartOfYear = function (date) {
    return dateFns.startOfYear(date);
  };

  DateUtils.prototype.getEndOfDay = function (date) {
    return dateFns.endOfDay(date);
  };

  DateUtils.prototype.getEndOfWeek = function (date) {
    return dateFns.endOfWeek(date);
  };

  DateUtils.prototype.getEndOfMonth = function (date) {
    return dateFns.endOfMonth(date);
  };

  DateUtils.prototype.getEndOfYear = function (dateLeft) {
    return dateFns.endOfYear(this.coerceToDate(dateLeft));
  };

  DateUtils.prototype.getDateDifference = function (dateLeft, dateRight) {
    return dateFns.differenceInMilliseconds(dateLeft, dateRight);
  };

  DateUtils.prototype.getDifferenceInMinutes = function (dateLeft, dateRight) {
    return dateFns.differenceInMinutes(this.coerceToDate(dateLeft), this.coerceToDate(dateRight));
  };

  DateUtils.prototype.getMinutesForToday = function () {
    return dateFns.getMinutes(this.getCurrentDate());
  };

  DateUtils.prototype.setMinutesForToday = function (minutes) {
    return dateFns.setMinutes(this.getCurrentDate(), minutes);
  };

  DateUtils.prototype.addYears = function (date, amount) {
    return dateFns.addYears(date, amount);
  };

  DateUtils.prototype.addMonths = function (date, amount) {
    return dateFns.addMonths(date, amount);
  };

  DateUtils.prototype.addDays = function (date, amount) {
    return dateFns.addDays(this.coerceToDate(date), amount);
  };

  DateUtils.prototype.addHours = function (date, amount) {
    return dateFns.addHours(date, amount);
  };

  DateUtils.prototype.addMinutes = function (date, amount) {
    return dateFns.addMinutes(date, amount);
  };

  DateUtils.prototype.subtractDays = function (date, amount) {
    return dateFns.subDays(this.coerceToDate(date), amount);
  };

  DateUtils.prototype.subtractHours = function (date, amount) {
    return dateFns.subHours(date, amount);
  };

  DateUtils.prototype.subtractWeeks = function (date, amount) {
    return dateFns.subWeeks(date, amount);
  };

  DateUtils.prototype.subtractMonths = function (date, amount) {
    return dateFns.subMonths(date, amount);
  };

  DateUtils.prototype.isSameOrBefore = function (dateLeft, dateRight) {
    return this.isSameYear(dateLeft, dateRight) && this.isSameMonth(dateLeft, dateRight) && this.isSameDay(dateLeft, dateRight) || this.isBefore(dateLeft, dateRight);
  };

  DateUtils.prototype.isSameOrAfter = function (dateLeft, dateRight) {
    return this.isSameYear(dateLeft, dateRight) && this.isSameMonth(dateLeft, dateRight) && this.isSameDay(dateLeft, dateRight) || this.isAfter(dateLeft, dateRight);
  };

  DateUtils.prototype.isSameYear = function (dateLeft, dateRight) {
    return dateFns.isSameYear(this.coerceToDate(dateLeft), this.coerceToDate(dateRight));
  };

  DateUtils.prototype.isSameMonth = function (dateLeft, dateRight) {
    return dateFns.isSameMonth(this.coerceToDate(dateLeft), this.coerceToDate(dateRight));
  };

  DateUtils.prototype.isSameDay = function (dateLeft, dateRight) {
    return dateFns.isSameDay(this.coerceToDate(dateLeft), this.coerceToDate(dateRight));
  };

  DateUtils.prototype.isAfter = function (date, dateToCompare) {
    return dateFns.isAfter(this.coerceToDate(date), this.coerceToDate(dateToCompare));
  };

  DateUtils.prototype.isBefore = function (date, dateToCompare) {
    return dateFns.isBefore(this.coerceToDate(date), this.coerceToDate(dateToCompare));
  };

  return DateUtils;
}();

exports.dateUtils = new DateUtils();