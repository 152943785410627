"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var csn_utils_1 = require("../../utils/csn.utils");

var ca_lazy_load_image_styles_1 = require("./ca-lazy-load-image.styles");

exports.CaLazyLoadImageView = function (_a) {
  var _b;

  var alt = _a.alt,
      src = _a.src,
      thumbnail = _a.thumbnail,
      className = _a.className;
  var classes = ca_lazy_load_image_styles_1.useStyles();

  var _c = react_1.useState(false),
      loaded = _c[0],
      setLoaded = _c[1];

  return react_1.default.createElement("div", {
    className: csn_utils_1.csn(className, classes.root),
    style: {
      backgroundImage: thumbnail
    }
  }, react_1.default.createElement("img", {
    className: csn_utils_1.csn(classes.img, (_b = {}, _b[classes.imgHidden] = !loaded, _b)),
    alt: alt,
    src: src,
    onLoad: function onLoad() {
      return setLoaded(true);
    }
  }));
};