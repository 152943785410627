import { CaCookieConsent } from 'ca-shared';
import React from 'react';
import { Subject } from 'rxjs';
import { logRender } from '../../shared/utils/log.utils';
import { AppPage, AppTab } from '../../types';
import { useGmSelector } from '../custom-hooks';
import GmAppBar from '../gm-app-bar';
import { GmAppTabs } from '../gm-app-tabs';
import { GmCraftWizard } from '../gm-craft-wizard';
import { GmCurrentGoals } from '../gm-current-goals';
import GmGoalTree from '../gm-goal-tree';
import { GmMorePage } from '../gm-more-page';
import { GmWelcomePage } from '../gm-welcome-page';
import { useStyles } from './gm-root.styles';

export const GmRootView: React.FC = () => {
  logRender(GmRootView);
  const classes = useStyles();
  const rootScroll$ = new Subject<number>();

  const appTab = useGmSelector((state) => state.selectedAppTab);
  const appPage = useGmSelector(
    (state) => state.localSettings.appPage,
  );

  if (appPage === AppPage.main) {
    let content: JSX.Element;
    switch (appTab) {
      case AppTab.all:
        content = <GmGoalTree />;
        break;

      case AppTab.inProgress:
        content = <GmCurrentGoals />;
        break;

      case AppTab.craft:
        content = <GmCraftWizard />;
        break;

      case AppTab.more:
        content = <GmMorePage />;
        break;

      default:
        content = <div />;
        break;
    }
    return (
      <div
        className={classes.root}
        onScroll={(event) => {
          rootScroll$.next(event.currentTarget.scrollTop);
        }}>
        <GmAppBar appTab={appTab} rootScroll$={rootScroll$} />
        <div className={classes.contentContainer}>{content}</div>
        <GmAppTabs />
        <CaCookieConsent appName="myGoalCraft" />
      </div>
    );
  }
  return <GmWelcomePage />;
};
