import { debounceTime, filter, map } from 'rxjs/operators';
import { ShouldSaveUserData, syncUserData } from '../redux-actions';
import { GmEpic } from '../types';

export const userDataChangeTriggerEpic: GmEpic = (action$, store) =>
  action$.pipe(
    filter(
      // tslint:disable-next-line: no-any
      (action: any) => (action as ShouldSaveUserData).saveUserData,
    ),
    debounceTime(200),
    map(() => syncUserData()),
  );
