import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FilterIcon from '@material-ui/icons/FilterList';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import { dateUtils as du } from 'ca-shared';
import React, { useCallback, useState } from 'react';
import auth from '../../core/auth';
import { colors } from '../../shared/theme';
import { logRender } from '../../shared/utils/log.utils';
import { AppTab, UserData } from '../../types';
import { apiCall, authFilter } from '../../utils/request.utils';
import { useGmDispatch } from '../custom-hooks';
import { GmConfirmationDialog } from '../gm-confirmation-dialog';
import { useStyles } from './gm-more-page.styles';

type Backup = Pick<UserData, 'goals' | 'lastModified'>;

const myEmail = 'viktor@mygoalcraft.com';
const supportEmail = 'support@mygoalcraft.com';

const features = [
  {
    name: 'Pending Goals',
    desc: `Mark goal as pending to hide it
      from the list of goals that are in progress or
      to filter it in the goal tree.`,
    icon: QueryBuilderIcon,
    color: colors.goal.amber,
  },
  {
    name: 'Filter Goals',
    desc: `Hide pending or/and completed goals,
    to focus attention on what is important at the moment.`,
    icon: FilterIcon,
    color: colors.goal.blue,
  },
  {
    name: 'Checklists',
    desc: `Set a strict list of criteria to define
    when the goal can be considered complete`,
    icon: PlaylistAddCheckIcon,
    color: colors.goal.green,
  },
  {
    name: `Goal's Priority`,
    desc: `Set priority to define which goals
    have to be higher in the list.`,
    icon: LowPriorityIcon,
    color: colors.goal.teal,
  },
];

async function getBackups() {
  if (authFilter()) {
    return apiCall('GET', 'backups').toPromise();
  }
  return Promise.resolve(null);
}

export const GmMorePageView: React.FC = () => {
  logRender(GmMorePageView);

  const classes = useStyles();
  const [backups, setBackups] = useState<Array<Backup>>([]);
  const [pendingBackup, setPendingBackup] = useState<Backup | null>(
    null,
  );

  const [act, dispatch] = useGmDispatch();

  const handleCloseRestoreDial = useCallback(
    (confirm: boolean) => {
      if (confirm && pendingBackup) {
        dispatch(act.restoreBackup(pendingBackup.goals));
        dispatch(act.changeAppTab(AppTab.all));
      }
      setPendingBackup(null);
    },
    [setPendingBackup, pendingBackup, act, dispatch],
  );

  return (
    <div className={classes.root}>
      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Roadmap</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.roadMapPanel}>
          <Typography
            variant="subtitle1"
            align="center"
            gutterBottom={true}>
            Features that are currently being implemented
          </Typography>
          {features.map((iter) => (
            <Paper
              key={iter.name}
              className={classes.roadMapItem}
              style={{
                backgroundColor: iter.color,
              }}>
              <Typography
                variant="h6"
                className={classes.roadMapItemTitle}>
                {<iter.icon className={classes.roadMapItemIcon} />}
                {iter.name}
              </Typography>

              <Typography>{iter.desc}</Typography>
            </Paper>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      {auth.authorized && (
        <ExpansionPanel
          TransitionProps={{
            onEntered: () => {
              if (!backups.length) {
                getBackups().then((result) => {
                  if (
                    result &&
                    result.response &&
                    Array.isArray(result.response) &&
                    result.response.length
                  ) {
                    const retrievedBackups: Array<Backup> = [
                      ...result.response,
                    ];
                    retrievedBackups.sort(
                      (el1, el2) =>
                        (el2.lastModified || 0) -
                        (el1.lastModified || 0),
                    );

                    if (
                      retrievedBackups.length >= 2 &&
                      retrievedBackups[0].lastModified ===
                        retrievedBackups[1].lastModified
                    ) {
                      retrievedBackups.shift();
                    }
                    setBackups(retrievedBackups);
                  }
                });
              }
            },
          }}>
          <ExpansionPanelSummary
            className={classes.panelSummary}
            expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h6">Restore from History</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <div className={classes.backupsContainer}>
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom={true}>
                Select a version to restore
              </Typography>
              {backups.length ? (
                backups.map((iter) => {
                  return iter.lastModified ? (
                    <div
                      className={classes.backup}
                      key={iter.lastModified}>
                      <Typography>
                        {du.getFormattedDate(
                          iter.lastModified,
                          'EEE. MMM dd, yyyy - hh:mm a',
                        )}
                      </Typography>
                      <br />
                      <Typography>
                        {iter.goals.length + ' goals'}
                      </Typography>
                      <br />
                      <Button
                        variant="contained"
                        className={classes.restoreButton}
                        color="secondary"
                        onClick={() => {
                          setPendingBackup(iter);
                        }}>
                        Restore this version
                      </Button>
                    </div>
                  ) : null;
                })
              ) : (
                <CircularProgress className={classes.spinner} />
              )}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      )}

      <ExpansionPanel>
        <ExpansionPanelSummary
          className={classes.panelSummary}
          expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Contacts</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography
            className={classes.contacts}
            variant="subtitle1"
            align="center"
            gutterBottom={true}>
            You can send your suggestions, complains or ideas to
            <br />
            <br />
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
            <br /> or
            <br />
            <a href={`mailto:${myEmail}`}>{myEmail}</a>
            <br />
            <br />
            Any feedback would be greatly appreciated!
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <GmConfirmationDialog
        title={'Restore this version ?'}
        text={
          pendingBackup
            ? `${du.getFormattedDate(
                pendingBackup.lastModified as number,
                'EEE. MMM dd, yyyy - hh:mm a',
              )}

              Caution: after restoring this state, all current data will be lost!`
            : undefined
        }
        open={Boolean(pendingBackup)}
        confirmButtonText="Restore"
        onClose={handleCloseRestoreDial}
      />
    </div>
  );
};
