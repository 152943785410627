import { combineReducers, Reducer } from 'redux';
import { StoreState } from '../types/store-state.types';
import { selectedAppTab } from './app-tabs.reducer';
import userData from './goals.reducer';
import { localSettings } from './local-settings.reducer';
// INSERT HERE

const rootReducers: {
  // tslint:disable-next-line: no-any
  [K in keyof StoreState]: Reducer<StoreState[K], any>;
} = {
  userData,
  selectedAppTab,
  localSettings,
  // INSERT HERE
};

export const rootReducer = combineReducers<StoreState>(rootReducers);
